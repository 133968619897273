import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Tabs, Tab, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector } from 'react-redux';
import './charts.scss';

interface BIProps {
    data: any,
    template: any
}

export const BI = (props: BIProps) => {
    const customersRef = useSelector((state: any) => state.customersRef);
    const [rtData, setRtData] = useState(typeof props.data === "string" ? {} : props.data);
    const [charts, setCharts] = useState(JSON.parse(JSON.stringify(props.template.chartsLists)));

    const getChart = (field: any) => {
        let chart: any;

        if (!rtData[field.name]) {
            //return;
            rtData[field.name] = { results: [{}] }
        }

        switch (field.type) {
            case "SummaryCards":
                chart = (
                    <div className="summaryWrap">
                        {field.keys.values.length > 4 ?
                            <>
                                <i className='fds-glyphs-arrow-left3 icons left' onClick={(ev: any) => {
                                    const sw: any = document.querySelector(".summary.chartsWrap");
                                    const prev = sw.scrollLeft;
                                    sw.scrollLeft -= 350;
                                    if (sw.scrollLeft === 0 || sw.scrollLeft === prev) {
                                        const left: any = document.querySelector(".icons.left");
                                        left.style.display = "none";
                                    }
                                    if (sw.scrollLeft < prev) {
                                        const left: any = document.querySelector(".icons.right");
                                        left.style.display = "block";
                                    }
                                }} />
                                <i className='fds-glyphs-arrow-right3 icons right' onClick={() => {
                                    const sw: any = document.querySelector(".summary.chartsWrap");
                                    const prev = sw.scrollLeft;
                                    sw.scrollLeft += 350;
                                    if (sw.scrollLeft > prev) {
                                        const left: any = document.querySelector(".icons.left");
                                        left.style.display = "block";
                                    }
                                    if (sw.scrollLeft === prev || sw.scrollLeft - prev < 350) {
                                        const left: any = document.querySelector(".icons.right");
                                        left.style.display = "none";
                                    }
                                }} />
                            </> : null
                        }
                        {<Row className='summary chartsWrap tilesWrap'>
                            {field.keys.values?.map((v: any, vi: number) => {
                                return (
                                    <Col className={`${vi === 0 && "ps-0"} mb-3 ${field.keys.values.length - 1 === vi ? 'pe-0' : ''}`} key={vi}>
                                        <Card className="primary left">
                                            <Card.Body>
                                                <Card.Text className='pb-1'>

                                                    {rtData[field.name].results[0][v + "_percent_diff"] && (!field.keys.extensionAllowed?.[vi] || !field.keys?.multiValueExtensions || field.keys?.multiValueExtensions?.length <= 0 || !field.keys.extensionAllowed[vi]) ?
                                                        <div className={`trend right ${rtData[field.name].results[0][v + "_percent_diff"] >= 0 ? 'successTxt' : 'dangerTxt'}`}>
                                                            <span className='trendText pe-2'>{((rtData[field.name].results[0][v + "_percent_diff"] || 0) * 100)?.toFixed(2)}%</span>
                                                            {rtData[field.name].results[0][v + "_percent_diff"] === 0 ?
                                                                <i className='fds-glyphs-minus3' /> :
                                                                rtData[field.name].results[0][v + "_percent_diff"] > 0 ?
                                                                    <i className='fds-glyphs-arrow-up-right' /> :
                                                                    <i className='fds-glyphs-arrow-down-right' />
                                                            }
                                                        </div> : null
                                                    }

                                                    {
                                                        ((field.keys?.multiValueExtensions && field.keys.extensionAllowed?.[vi]) ? field.keys?.multiValueExtensions : [{ key: "" }])?.map((mv: any, mvi: number) => {
                                                            return (
                                                                <>
                                                                    {`${v}${mv.key}` in rtData[field.name].results[0] ?
                                                                        <>
                                                                            {field.keys?.multiValueExtensions && mvi > 0 ? <hr style={{ margin: "0.5rem 0" }} /> : null}
                                                                            <h1 style={{ display: "flex" }} key={mvi}>
                                                                                {field.keys.symbol?.[vi] !== "%" && field.keys.symbol?.[vi]}{FDS.numberFormatTxt(rtData[field.name]?.results?.[0]?.[v + mv.key] || 0, 0, "")}{field.keys.symbol?.[vi] === "%" && field.keys.symbol?.[vi]}

                                                                                {field.keys.extensionAllowed?.[vi] &&
                                                                                    <span className='rightLabel'>
                                                                                        {`${v}${mv.key}_percent_diff` in rtData[field.name].results[0] && rtData[field.name].results[0][v + mv.key + "_percent_diff"] > 0 ?
                                                                                            <OverlayTrigger
                                                                                                placement="auto"
                                                                                                overlay={<Tooltip>{FDS.numberFormatTxt(rtData[field.name].results[0][v + mv.key + "_diff"], 2, field.keys.symbol?.[vi])}</Tooltip>}
                                                                                            >
                                                                                                <i className='fds-glyphs-arrow-up successTxt' />
                                                                                            </OverlayTrigger>
                                                                                            :
                                                                                            `${v}${mv.key}_percent_diff` in rtData[field.name].results[0] && rtData[field.name].results[0][v + mv.key + "_percent_diff"] < 0 ?
                                                                                                <OverlayTrigger
                                                                                                    placement="auto"
                                                                                                    overlay={<Tooltip>{FDS.numberFormatTxt(rtData[field.name].results[0][v + mv.key + "_diff"], 2, field.keys.symbol?.[vi])}</Tooltip>}
                                                                                                >
                                                                                                    <i className='fds-glyphs-arrow-down dangerTxt' />
                                                                                                </OverlayTrigger> :
                                                                                                ""
                                                                                        }
                                                                                        <span className='ps-1'>{mv.label}</span>
                                                                                    </span>
                                                                                }
                                                                            </h1>
                                                                        </> :
                                                                        null
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }

                                                    {/* <h1 style={{ display: "flex" }}>
                                                        {field.keys.symbol?.[vi] !== "%" && field.keys.symbol?.[vi]}{FDS.numberFormatTxt(rtData[field.name]?.results?.[0]?.[v] || 0, 0, "")}{field.keys.symbol?.[vi] === "%" && field.keys.symbol?.[vi]}
                                                        {`${v}_benchmark` in rtData[field.name].results[0] && <span className='rightLabel'>
                                                            {`${v}_percent_diff` in rtData[field.name].results[0] && rtData[field.name].results[0][v + "_percent_diff"] > 0 ?
                                                                <OverlayTrigger
                                                                    placement="auto"
                                                                    overlay={<Tooltip>{rtData[field.name].results[0][v + "_diff"]}</Tooltip>}
                                                                >
                                                                    <i className='fds-glyphs-arrow-up successTxt' />
                                                                </OverlayTrigger>
                                                                :
                                                                `${v}_percent_diff` in rtData[field.name].results[0] && rtData[field.name].results[0][v + "_percent_diff"] < 0 ?
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={<Tooltip>{rtData[field.name].results[0][v + "_diff"]}</Tooltip>}
                                                                    >
                                                                        <i className='fds-glyphs-arrow-down dangerTxt' />
                                                                    </OverlayTrigger> :
                                                                    ""
                                                            }
                                                            Agency
                                                        </span>}
                                                    </h1>

                                                    {`${v}_benchmark` in rtData[field.name].results[0] &&
                                                        <span>
                                                            <hr style={{ margin: "0.5rem 0" }} />
                                                            <h1 style={{ display: "flex" }}>
                                                                {field.keys.symbol?.[vi] !== "%" && field.keys.symbol?.[vi]}
                                                                {FDS.numberFormatTxt(rtData[field.name]?.results?.[0]?.[v + "_benchmark"] || 0, 0, "")}
                                                                {field.keys.symbol?.[vi] === "%" && field.keys.symbol?.[vi]}

                                                                <span className='rightLabel'>
                                                                    {`${v}_benchmark_percent_diff` in rtData[field.name].results[0] && rtData[field.name].results[0][v + "_benchmark_percent_diff"] > 0 ?
                                                                        <OverlayTrigger
                                                                            placement="auto"
                                                                            overlay={<Tooltip>{rtData[field.name].results[0][v + "_benchmark_diff"]}</Tooltip>}
                                                                        >
                                                                            <i className='fds-glyphs-arrow-up successTxt' />
                                                                        </OverlayTrigger>
                                                                        :
                                                                        `${v}_benchmark_percent_diff` in rtData[field.name].results[0] && rtData[field.name].results[0][v + "_benchmark_percent_diff"] < 0 ?
                                                                            <OverlayTrigger
                                                                                placement="auto"
                                                                                overlay={<Tooltip>{rtData[field.name].results[0][v + "_benchmark_diff"]}</Tooltip>}
                                                                            >
                                                                                <i className='fds-glyphs-arrow-down dangerTxt' />
                                                                            </OverlayTrigger>
                                                                            :
                                                                            ""}
                                                                    Benchmark
                                                                </span>
                                                            </h1>
                                                        </span>
                                                    } */}

                                                    <h3 style={{ textTransform: "capitalize", marginTop: "1rem" }}>{field?.keys?.vLabels?.[vi] || v}</h3>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>}
                    </div>
                );
                break;
            case "XYChart":
                chart = (
                    <FDS.XYChart
                        id={field.name}
                        title={field.label}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        totalRecords={rtData[field.name].totalRecords}
                        enableBars={field.enableBars}
                        enableLines={field.enableLines}
                        enableDots={field.enableDots}
                        enableArea={field.enableArea}
                        enableValues={field.enableValues}
                        disableValuesForIndexes={field.disableValuesForIndexes}
                        sideBySide={field.sideBySide}
                        labelIndex={field.labelIndex}
                        labels={field.labels}
                        keys={{ ...field.keys, values: [...field.keys.values, ...(field.keys.past || [])] }}
                        style={{ width: 900, height: 460 }}
                        sortKey={field.key?.sort}
                        theme={field.theme}
                    />
                );
                break;
            case "BarChart":
                chart = (
                    <FDS.BarChart
                        id={field.name}
                        title={field.label}
                        orientation={field.orientation}
                        stacked={field.stacked}
                        merged={field.merged}
                        sideBySide={field.sideBySide}
                        labelIndex={field.labelIndex}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        totalRecords={rtData[field.name].totalRecords}
                        labels={field.labels}
                        keys={{ ...field.keys, values: [...field.keys.values, ...(field.keys.past || [])] }}
                        style={{ width: 600, height: 500 }}
                        sortKey={field.key?.sort}
                        theme={field.theme}
                    />
                );
                break;
            case "DonutChart":
                chart = (
                    <FDS.DonutChart
                        id={field.name}
                        title={field.label}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        totalRecords={rtData[field.name].totalRecords}
                        showTotal={field.showTotal}
                        totalText={field.totalText}
                        sideBySide={field.sideBySide}
                        keys={field.keys}
                        style={{ width: 460 }}
                        sortKey={field.key?.sort}
                        theme={field.theme}
                    />
                );
                break;
            case "BarPercentChart":
                chart = (
                    <FDS.BarPercentChart
                        id={field.name}
                        title={field.label}
                        data={rtData[field.name]?.results?.[0]?.[field.keys?.values?.[0] || 0] || 0}
                        orientation="H"
                        style={{ width: 100, height: 100 }}
                    />
                );
                break;
            case "DonutPercentageChart":
                break;
            case "TableChart":
                chart = (
                    <FDS.TableChart
                        id={field.name}
                        title={field.label}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        keys={field.keys}
                        sortKey={field.key?.sort}
                    />
                );
                break;
            case "CirclePackageChart":
                chart = (
                    <FDS.CirclePackageChart
                        id={field.name}
                        title={field.label}
                        data={field.keys.sort ? rtData[field.name].results.sort((a: any, b: any) => a[field.keys.sort] > b[field.keys.sort] ? -1 : 1) : rtData[field.name].results}
                        totalRecords={rtData[field.name]?.results?.children?.length}
                        enableLabels={true}
                        enableValues={true}
                        showTotal={true}
                        totalText="Total Product Count"
                        keys={field.keys}
                        style={{ width: 560 }}
                        sortKey={field.key?.sort}
                    />
                );
                break;
            default:

        }

        return chart;
    }

    const getTab = (list: any, li: number) => {
        return list.wraps.map((wrap: any, wi: number) => {
            if (wrap.tabs) {
                return (
                    <div key={wi}>
                        <Tabs onClick={() => { setRtData(JSON.parse(JSON.stringify(props.data))) }} className='mt-3' defaultActiveKey="totalSales" transition={false}>
                            {wrap.tabs.map((tab: any) => {
                                return (
                                    <Tab className='row' eventKey={tab.name} title={tab.label}>
                                        {tab.fields.map((field: any) => {
                                            return (
                                                <>
                                                    {
                                                        !field.showFor || customersRef.customer?.data_content?.indexOf(field.showFor?.subscription) >= 0 ?
                                                            <Col key={field.name} md={field.spacing}>
                                                                {getChart(field)}
                                                            </Col> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })}
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div >
                )
            } else {
                return (
                    <div key={wi}>
                        {wrap.showName ? <h2 className='mt-3'>{wrap.label}</h2> : null}
                        {wrap.compareTrendOptions?.length > 0 &&
                            <Row id={"compareTrendOptions_" + wrap.name} className='compareTrendOptions pt-3'>
                                <Col style={{ flex: 0 }}>
                                    <label htmlFor="" className='pt-1 pb-1'>Compare:</label>
                                </Col>
                                {wrap.compareTrendOptions?.map((tOp: string, tOpi: number) => {
                                    return (
                                        <Col className='compareChecks' key={tOp} style={{ flex: 0 }}>
                                            <Form.Check type="switch" label={tOp} name={tOp} style={{ display: 'inline-block' }} onChange={(ev: any) => {
                                                wrap.clicked = true;
                                                if (ev.target.checked) {
                                                    wrap.fields?.map((field: any) => {
                                                        const name = field.serviceKeys?.values[0] + ev.target.name;
                                                        field.keys?.values?.push(name);
                                                        field.keys?.legends?.push(field.keys.legendToAppend ? field.keys.legendToAppend + ev.target.name : name);
                                                        field.keys?.values?.sort((a: string, b: string) => a > b ? 1 : -1);
                                                        field.keys?.legends?.sort((a: string, b: string) => a > b ? 1 : -1);
                                                        return field;
                                                    });
                                                } else {
                                                    wrap.fields?.map((field: any) => {
                                                        const pos = field.keys?.values.indexOf(field.serviceKeys?.values[0] + ev.target.name);
                                                        field.keys?.values?.splice(pos, 1).sort((a: string, b: string) => a > b ? 1 : -1);
                                                        field.keys?.legends?.splice(pos, 1).sort((a: string, b: string) => a > b ? 1 : -1);
                                                        return field;
                                                    });
                                                }
                                                setCharts((prev: any) => {
                                                    prev[li] = list;
                                                    return [...prev]
                                                })
                                            }} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        }
                        <Row key={wi}>
                            {
                                wrap.fields.map((field: any, fi: number) => {
                                    return (
                                        <>
                                            {
                                                !field.showFor || customersRef.customer?.data_content?.indexOf(field.showFor?.subscription) >= 0 ?
                                                    <Col className="mt-3 mb-3" key={fi} md={field.spacing}>
                                                        {getChart(field)}
                                                    </Col> :
                                                    null
                                            }
                                        </>
                                    )
                                })
                            }
                        </Row>
                    </div>
                )
            }
        })
    }

    useEffect(() => {
        setRtData(props.data);
    }, [props.data]);

    useEffect(() => {
        setCharts(JSON.parse(JSON.stringify(props.template.chartsLists)));
        if (props.template?.checkTrendOptions) {
            setTimeout(() => {
                const el: any = document.querySelectorAll(`#compareTrendOptions_${props.template.checkTrendOptions[0]} .compareChecks .form-check-input`)?.[0];
                el.click();
            }, 100);
        }
    }, [props.template.chartsLists, props.template.checkTrendOptions]);

    return (
        <div className='chartsPage'>
            {
                charts.length > 1 ?
                    <Tabs defaultActiveKey="tab0" transition={false} onClick={() => {
                        setRtData(JSON.parse(JSON.stringify(props.data)))
                    }}>
                        {
                            charts?.map((list: any, li: number) => {
                                return charts.length > 1 ?
                                    <Tab key={li} eventKey={`tab${li}`} title={list.label}>
                                        {getTab(list, li)}
                                    </Tab> :
                                    null
                            })
                        }
                    </Tabs> :
                    <div>
                        {
                            charts?.map((list: any, li: number) => {
                                return (
                                    <>
                                        {
                                            charts.length === 1 ?
                                                <div key={li}>
                                                    {getTab(list, li)}
                                                </div> : null
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}