import { useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
// import { textToDate } from "@arctravel/react-fds/lib";
import { setSavedTemplatesAction } from '../../store/actions'
import { useSelector, useDispatch } from 'react-redux';
import './modal.scss';

interface ScheduleTemplateProps {
    ip: any,
    refinedIp: any,
    show: boolean,
    type: string,
    savedTemplates: any[],
    selectedTemplate: any,
    onHide: any
}

const Schedular = (props: ScheduleTemplateProps) => {
    const dispatch = useDispatch();
    const customersRef: any = useSelector((state: any) => state.customersRef);

    const [selectDate, setSelectDate] = useState(new Date());

    const modeButton = ["relative", "absolute"];
    const [viewAs, setViewAs] = useState(customersRef.viewAs);

    const defaultButton = () => {

        let i = 1;

        if (props.type === "JOURNEY" || props.type === "ROUTING") {
            const j_period = Object.entries(props.refinedIp.report).filter(([key]) => key.includes("_date"))
            const j_dates: any = j_period[0]?.[1]
            const endDate = j_dates?.endDate
            if (!endDate) {
                i = 1;
            } else {
                if (endDate?.length >= 2) {
                    i = 1
                } else {
                    const monthYear = endDate[0].split("/")
                    const [month, year] = [monthYear[0], monthYear[monthYear.length === 2 ? 1 : 2]]
                    const lastDayOfMonth = new Date(year, month, 0).getDate()
                    const selectedDate = `${month}/${lastDayOfMonth}/${year}`
                    new Date(selectedDate).setHours(0, 0, 0, 0) >= selectDate.setHours(0, 0, 0, 0) ? i = 1 : i = 0
                }
            }
        }

        if (props.type === "INTERACTIVE") {
            const purchaseEndDate = props.refinedIp.report.purchase_date?.endDate
            const travelEndDate = props.refinedIp.report.travel_date?.endDate
            if (!purchaseEndDate && !travelEndDate) {
                i = 1;
            } else {
                if (purchaseEndDate && travelEndDate && Object.keys(props.refinedIp?.report?.purchase_date).length === 2 && Object.keys(props.refinedIp?.report?.travel_date).length === 2) {
                    // Both period selected
                    if (purchaseEndDate.length >= 2 || travelEndDate.length >= 2) {
                        // compare selected
                        i = 1
                    } else {
                        // compare greater date from selection
                        new Date(purchaseEndDate).setHours(0, 0, 0, 0) >= new Date(travelEndDate).setHours(0, 0, 0, 0) ?
                            new Date(purchaseEndDate).setHours(0, 0, 0, 0) >= selectDate.setHours(0, 0, 0, 0,) ? i = 1 : i = 0 :
                            new Date(travelEndDate).setHours(0, 0, 0, 0) >= selectDate.setHours(0, 0, 0, 0) ? i = 1 : i = 0
                    }
                } else if (purchaseEndDate && Object.keys(props.refinedIp?.report?.purchase_date).length === 2) {
                    // purchase only selected
                    purchaseEndDate.length >= 2 ? i = 1 : new Date(purchaseEndDate).setHours(0, 0, 0, 0) >= selectDate.setHours(0, 0, 0, 0,) ? i = 1 : i = 0
                } else if (travelEndDate && Object.keys(props.refinedIp?.report?.travel_date).length === 2) {
                    // travel only selected
                    travelEndDate.length >= 2 ? i = 1 : new Date(travelEndDate).setHours(0, 0, 0, 0) >= selectDate.setHours(0, 0, 0, 0,) ? i = 1 : i = 0
                } else {
                    i = 1
                }
            }

        }

        return modeButton[i]
    }

    const [item, setItem]: any = useState(defaultButton());

    const initialState = {
        template_name: "",
        template_description: "",
        shared: "0",
        schedule: {
            startDate: `${selectDate.toLocaleDateString('us', { year: "numeric", month: "2-digit", day: "2-digit" })} 23:50:00`,
            frequency: "daily",
            executeMode: item,
            endDate: "",
            status: "Active",
            modeOfDelivery: "AUTHENTICATED_DOWNLOAD",  // PRE_SIGNED_URL, AUTHENTICATED_DOWNLOAD
            request: {
                reportTemplate: "",
                reportOutputPreference: "",
                reportType: ""
            }
        }
    }

    const [saveIp, setSaveIp]: any = useState(initialState);

    const handleClose = () => {
        props.onHide();
    }

    const onInputChange = (ev: any) => {
        setSaveIp((prev: any) => {
            prev[ev.target.name] = ev.target.value;
            prev.schedule.request["reportTemplate"] = props.refinedIp["report"];
            prev.schedule.request["reportOutputPreference"] = props.refinedIp["reportPreference"];
            prev.schedule.request["reportType"] = props.refinedIp["reportType"];
            return { ...prev };
        })
    }

    const onInputChangeSchedule = (ev: any) => {
        setSaveIp((prev: any) => {
            prev.schedule[ev.target.name] = ev.target.value;
            return { ...prev };
        })
    }

    const handleModeChange = (e: any) => {
        e.persist();
        // toggle radio
        setItem(e.target.value);
        // saving to object
        setSaveIp((prev: any) => {
            prev.schedule.executeMode = e.target.value;
            return { ...prev }
        })
    };

    const save = (ip: any) => {
        dispatch(setSavedTemplatesAction(ip, props.type));
    };

    const saveTemplate = () => {
        console.log('saveip', saveIp);
        handleClose();
        const r: any = JSON.parse(JSON.stringify(
            {
                template: {
                    report_type: props.type.toUpperCase(),
                    "template_name": saveIp.template_name,
                    "template_description": saveIp.template_description,
                    "shared": saveIp.shared
                },
                request: {
                    ...props.ip,
                    ...saveIp,
                }
            }
        ));

        if (viewAs?.org) {
            r.template["viewAs"] = {
                "entity": viewAs?.entity?.toUpperCase(),
                "org": viewAs?.org,
                "role": viewAs?.role?.toUpperCase()
            }
        }

        save(r);
        return false
    }

    const checkDuplicateTemplateName = () => {

        const isDuplicate = props.savedTemplates
            .filter(item => {
                return item.template_name?.toLowerCase().trim() === saveIp.template_name?.toLowerCase().trim()
                    && item?.scheduled === "true"
                    && item.user_org === viewAs.org
            })?.length > 0

        return isDuplicate ? true : false
    }

    useEffect(() => {
        setViewAs(customersRef.viewAs);
    }, [customersRef.viewAs]);

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" centered contentClassName="modal-height" show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Schedule a Report</Modal.Title>
                {/* <span>File Type Is CSV</span> */}
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className="form-group" controlId="frequency">
                                <Form.Label>Frequency</Form.Label>
                                <div className="select">
                                    <Form.Select aria-label='Select schedule' name="frequency" value={saveIp.schedule.frequency} onChange={onInputChangeSchedule}>
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </Form.Select>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="nameIp">
                                <Form.Label>Start Date</Form.Label>
                                <DatePicker
                                    id="startDateSelector"
                                    className="form-control type-body-primary-on-light"
                                    placeholderText="From"
                                    // showTimeSelect
                                    dateFormat="MMM dd yyyy"
                                    // dateFormat="MMM dd yyyy hh:mm a"
                                    selected={selectDate}
                                    disabled={false}
                                    minDate={new Date()}
                                    startDate={new Date()}
                                    isClearable={false}
                                    strictParsing
                                    onChange={(data: Date) => {
                                        setSelectDate(new Date(data));
                                        setSaveIp((prev: any) => {
                                            prev.schedule['startDate'] = `${data.toLocaleDateString('us', { year: "numeric", month: "2-digit", day: "2-digit" })} 23:50:00`;
                                            return { ...prev };
                                        })
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="executeModeIp">
                                {modeButton.map((btn, i) => {
                                    return (
                                        <Form.Check
                                            key={i}
                                            inline
                                            value={btn}
                                            name={btn}
                                            type="radio"
                                            label={btn[0].toUpperCase() + btn.substring(1)}
                                            onChange={e => handleModeChange(e)}
                                            checked={btn === item}
                                        />
                                    )
                                })}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="nameIp">
                                <Form.Label>Template Name *</Form.Label>
                                <Form.Control type="text" name="template_name" autoComplete="off" value={saveIp.template_name} onChange={onInputChange} />
                                {checkDuplicateTemplateName() ?
                                    <Form.Text className="danger pb-1">Template Name (<b>{saveIp.template_name.toUpperCase()}</b>) is already taken.</Form.Text> :
                                    null
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="descriptionIp">
                                <Form.Label>Template Summary (Optional)</Form.Label>
                                <Form.Control as="textarea" rows={4} name="template_description" autoComplete="off" value={saveIp.template_description} onChange={onInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Form.Group controlId="methodIp">
                        <Form.Label>Delevery Method</Form.Label>
                        <Form.Control as="select" name="method" value={saveIp.template_description} onChange={onInputChange}>
                            <option value="all">All Methods</option>
                            <option value="email">Email</option>
                            <option value="notification">Notification</option>
                        </Form.Control>
                    </Form.Group> */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={saveTemplate} disabled={!saveIp.template_name.trim() || checkDuplicateTemplateName()}>
                    Save Schedule
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Schedular;