import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { Row, Col, Tab, Tabs, Spinner, Alert, Button } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { getOts, getOtsLength } from '../../okta/ls';
import { useDispatch, useSelector } from 'react-redux';
import { TitlePanel } from './TitlePanel';
import { DownloadPanel } from './DownloadPanel';
import { templates } from '../utils';
import { BI } from '../bi';
import { ARCCIDataStatus } from '../otherReports/arcci/ARCCIDataStatus';

import { FDSInfiniteTable } from '../utils/FDSInfiniteTable';

import { cdbdata } from '../utils/SampleData/cdb';

import './Resultspanel.scss';

declare const window: any;

interface ResultsProps {
    params: any,
    onLoadRun: boolean,
    staticFilter: boolean
}

export const ResultsPanel = (props: ResultsProps) => {
    const longRunMsg = "Report generating is taking a bit longer, just like taxing on the tarmac. Check your report history for the final report and continue working in the meantime.";
    const dispatch = useDispatch();
    const history = useHistory();
    // const notificationRef = useSelector((store: any) => store.notificationRef);
    const reportIpRef = useSelector((state: any) => state.reportIpRef);
    const customersRef = useSelector((state: any) => state.customersRef);
    const [tab, setTab] = useState("tab1");
    const [user, setUser] = useState(getOtsLength() > 0 ? getOts()?.idToken?.name : "");
    const [repName, setRepName] = useState("");
    const [count, setCount] = useState(0);
    const [data, setData] = useState({});
    const [isError, setIsError] = useState(-1);
    const [isToomanyCols, setToomanyCols] = useState(false);

    const defaultConfig = useMemo(() => {
        return {
            apiUrl: window.ENV.reportsDomain,
            token: localStorage.getItem("bearer") + '',
            data: {},
            pageRecordCount: 250,
            showTooltips: true,
            compacted: true,
            columns: [],
            borderedHeader: true,
            header: {},
            headerOnly: false,
            sort: true,
            totalLine: [],
            pivote: {
                rows: {
                    count: 0,
                    color: "info"
                },
                cols: {
                    count: 0,
                    color: "warning"
                }
            }
        }
    }, []);

    const [config, setConfig] = useState(defaultConfig);

    const tableChanged = (response: any) => {
        console.log("tableChanged", response);
        if (response?.status) {
            dispatch({ type: "LOADING", data: "" });
        }
        expandResults(null);
    }

    const showResults = () => {
        return (
            props.onLoadRun ?
                <BI data={JSON.parse(JSON.stringify(templates[props.params].reportName === "CARRIER_DASHBOARD" ? cdbdata : data))} template={templates[props.params]} /> :
                templates[props.params].chartsLists?.length > 0 ?
                    <Tabs className="nav-scoped" defaultActiveKey={"tab1"} transition={false}
                        onSelect={(key: any) => {
                            setTab(key);
                            if (key === "tab2" && Object.keys(data).length <= 0) {
                                getReport0({ sort_index: -1, sort_type: "ASC" }, true, repName, false);
                            }
                        }}>
                        <Tab eventKey="tab1" title="Detailed Data">
                            <div className="resultsWrap" style={{ marginTop: "-16px" }}>
                                <FDSInfiniteTable config={config} onChange={tableChanged} onSort={sortTable} />
                            </div>
                        </Tab>
                        <Tab id="Visualizations" eventKey="tab2" title="Visualizations">
                            {tab === "tab2" ? <BI data={JSON.parse(JSON.stringify(data))} template={templates[props.params]} /> : null}
                        </Tab>
                    </Tabs> :
                    <div className="resultsWrap" style={{ marginTop: "-16px" }}>
                        {isToomanyCols ?
                            <Alert data-testid="table-main-no-data" className="mt-3" variant="info">
                                <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                <div className="body">Too many columns to render for this search criteria. Download the report
                                    <Button className='p-0 ms-2 me-1' variant="link"
                                        onClick={() => history.push('/hub/' + repName)} style={{ marginTop: "-3px" }}>
                                        <b>here</b>
                                    </Button> to view the results.
                                </div>
                            </Alert> :
                            <FDSInfiniteTable config={config} onChange={tableChanged} onSort={sortTable} />
                        }
                    </div>
        );
    }

    const getReport0 = useCallback((sortObj: any, isGraph: boolean, reportName: string, isInit: boolean) => {
        dispatch({ type: "LOADING", data: "Hang tight, your report is generating. May take up to 30 seconds." });
        if (isInit) {
            setData({});
        }
        const repIp = JSON.parse(JSON.stringify(reportIpRef));
        delete repIp.executionType;
        if (!isGraph) {
            delete repIp.template.graphs;
        }

        if (reportName?.length > 0) {
            repIp.reportName = reportName;
        }

        if (Object.keys(repIp)?.length <= 0) {
            return;
        }

        axios.post(window.ENV.reportsDomain, { ...repIp, ...(templates[props.params].disablePagination ? {} : { pageIndex: -1, ...sortObj }) }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            setIsError(1);
            if (!response.data.hasOwnProperty("results")) {
                if (isGraph) {
                    setData(response.data);
                    if (!props.onLoadRun) {
                        setTab("tab1");
                        setTimeout(() => {
                            setTab("tab2");
                        }, 1000)
                    }
                }
            } else {
                setTab("tab1");
            }

            if (templates[props.params].noResultsProp) {
                setData(response.data);
            }

            setCount(response?.data?.totalRecords || (response?.data?.description?.indexOf("Zero") >= 0 ? 0 : Object.keys(response?.data)?.length) || 0);

            if (reportName.length === 0) {
                if (reportIpRef.executionType === "D") {
                    dispatch({ type: "LOADING", data: "File creation in progress." });
                }

                setRepName(response?.data?.reportName?.split('.')[0] || response?.data?.summary?.reportName);

                const newConfig: any = {
                    token: localStorage.getItem("bearer") + '',
                    header: response?.data?.headerRow,
                    headerOnly: response?.data?.headerOnly,
                    borderedHeader: response?.data?.headerRow && Object.keys(response?.data?.headerRow)?.length > 0 ? true : false,
                    data: {
                        reportName: response?.data?.reportName?.split('.')[0]
                    },
                    totalLine: response?.data?.totalLine,
                    totalLinePosition: "BOTTOM"
                };

                console.log("newConfig", newConfig)

                if (reportIpRef?.template?.rows?.length + reportIpRef?.template?.cols?.length > 0) {
                    newConfig.pivote = {
                        rows: {
                            count: reportIpRef?.template?.rows.length,
                            color: "info"
                        },
                        cols: {
                            count: reportIpRef?.template?.cols?.length,
                            color: "warning"
                        }
                    }
                }

                setConfig((prev: any) => {
                    return {
                        ...prev,
                        ...newConfig
                    }
                });
            }

            setToomanyCols(response?.data?.title?.toLowerCase()?.indexOf("column limit exceeded") > 0);

            // setTimeout(() => {
            //     dispatch({ type: "LOADING", data: "" });
            // }, 1000);

            if (isGraph) {
                setTimeout(() => {
                    dispatch({ type: "LOADING", data: "" });
                }, 1000);
            }
            
        }).catch((error: AxiosError) => {
            setIsError(0);
            setConfig((prev: any) => {
                return { ...prev, data: {} }
            });
            setData({});
            dispatch({ type: "REPORT_IP", data: {} });
            dispatch({ type: "LOADING", data: "" });

            if (error.response?.status === 504) { // Timeout
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Notification", message: longRunMsg } });
            } else if (error.response?.status === 507) { // Column Limit
                dispatch({ type: "STATUS", data: { type: "INFO", title: "Notification", message: "The number of columns which would be generated by this query exceeds 600. Try moving some fields to rows, and avoid using fields like travel date and purchase date as columns" } });
            } else {
                // TODO
                //dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `We’re sorry but your query exceeds the carry-on bag size limit. Please change your parameters.` } });
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${error.message})` } });
            }
            window.document.getElementById("reportPageLeftToggleRef")?.classList?.remove("min");
        });
    }, [reportIpRef, props.onLoadRun, props.params, dispatch]);

    const sortTable = (sortObj: any) => {
        console.log("SSSSSSS:", sortObj);
        getReport0({ ...sortObj, reportName: repName }, false, "", false);
    }

    const expandResults = (ev: any) => {
        const expanded = "expanded";
        if (ev) {
            ev.target?.classList.toggle(expanded);
            document.getElementById("resultsWrapBackdrop")?.classList.toggle("show");
            document.getElementById("resultsWrap")?.classList.toggle(expanded);
            document.getElementsByTagName("body")[0]?.classList.toggle("overflow-hidden");
        }
    }

    useEffect(() => {
        setData({});
        setTab("tab1");
    }, [props.onLoadRun, props.params])

    useEffect(() => {
        setConfig(defaultConfig)
    }, [props.params, defaultConfig]);

    useEffect(() => {
        if (Object.keys(reportIpRef).length > 1 && reportIpRef.reportType === props.params) {
            setConfig((prev: any) => {
                return {
                    ...prev,
                    token: localStorage.getItem("bearer") + '',
                    data: {}
                };
            });
            getReport0({ sort_index: -1, sort_type: "ASC" }, templates[props.params].onLoadRun, "", true);
        }
    }, [getReport0, reportIpRef, props.params]);

    useEffect(() => {
        setUser(getOtsLength() > 0 ? getOts()?.idToken?.name : "");
    }, [])

    return (
        <React.Fragment>
            <div id="resultsWrapBackdrop" className='fade modal-backdrop'></div>
            <DownloadPanel reportIpRef={reportIpRef} />
            <Col id="resultsWrap" className={`${props.onLoadRun ? 'darkbg spacing' : ''}`} style={{ overflow: 'auto' }}>
                {props.params !== "ARCCIDS" && config.data && Object.keys(config.data)?.length > 0 && Object.keys(reportIpRef).length > 1 && reportIpRef.executionType !== "D" ?
                    <div>
                        <Row className="pt-3">
                            {repName?.length > 0 ?
                                <Col>
                                    {props.onLoadRun ?
                                        <div><small id="reportNameRef">{repName}</small></div> :
                                        <h3 id="reportNameRef" className='type-heading-h3-primary'>{repName}</h3>
                                    }
                                    <div className="type-caption-on-light pb-3">{user} {FDS.dateToText(new Date(), "/", true, false)}</div>
                                </Col> :
                                null
                            }

                            <TitlePanel parentProps={props} reportIpRef={reportIpRef} expandResults={expandResults} count={count} tab={tab} repName={repName} />

                            {props.onLoadRun || templates[props.params].hasOwnProperty("chartsLists") ?
                                <div className='filterListWrap mt-3 mb-3'>
                                    {{ ...templates[props.params] }?.lists.map((list: any, q: number) => {
                                        return list.wraps.map((wrap: any, i: number) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {wrap.fields.map((field: any, j: number) => {
                                                        return (
                                                            <React.Fragment key={j}>
                                                                {
                                                                    reportIpRef.template[field.name]?.length > 0 || (typeof reportIpRef.template[field.name] === "object" && !Array.isArray(reportIpRef.template[field.name])) ? (
                                                                        <span className="fds-tag info ms-0 mb-1">
                                                                            {field.hLabel || field.label}:
                                                                            <b className='ps-2'>
                                                                                {
                                                                                    typeof reportIpRef.template[field.name] === "object" && !Array.isArray(reportIpRef.template[field.name]) ?
                                                                                        Object.keys(reportIpRef.template[field.name])
                                                                                            .sort((a: any, b: any) => a < b ? 1 : -1)
                                                                                            .map((item: any, k: number) => {
                                                                                                return (<span key={k}>
                                                                                                    {reportIpRef.template[field.name][item] ?
                                                                                                        <React.Fragment>
                                                                                                            {!field.startDate ? <span style={{ textTransform: 'capitalize' }}>{item}</span> : null}
                                                                                                            {field.startDate && item === "startDate" ?
                                                                                                                reportIpRef.template[field.name]["startDate"][0] + " " + (reportIpRef.template[field.name]["endDate"][0] ? FDS.valueOrDash(null) + " " + reportIpRef.template[field.name]["endDate"][0] : "") +
                                                                                                                (reportIpRef.template[field.name]["startDate"].length > 1 ? " vs " + reportIpRef.template[field.name]["startDate"][1] + " " + (reportIpRef.template[field.name]["endDate"][1] ? FDS.valueOrDash(null) + " " + reportIpRef.template[field.name]["endDate"][1] : "") : "") :
                                                                                                                null}
                                                                                                            {!field.startDate ? `[${reportIpRef.template[field.name][item]?.toString()}]   &nbsp;` : null}
                                                                                                        </React.Fragment>
                                                                                                        : null
                                                                                                    }
                                                                                                </span>)
                                                                                            }) :
                                                                                        Array.isArray(reportIpRef.template[field.name]) ?
                                                                                            <span>
                                                                                                {
                                                                                                    reportIpRef.template[field.name].map((item: string, i: number) => {
                                                                                                        return field.options.filter((opt: any) =>
                                                                                                            opt[field.codeKey] === item)[0]?.[field.labelKey] || item
                                                                                                    }).join(", ").toUpperCase()
                                                                                                }
                                                                                            </span> :
                                                                                            <span>
                                                                                                {
                                                                                                    (field.type === "select" ?
                                                                                                        field.options.filter((opt: any) => opt['key']?.toLowerCase() === reportIpRef.template[field.name].toLowerCase())[0]?.['value'] :
                                                                                                        reportIpRef.template[field.name]?.toString().replace(/,/gi, ', ')).toUpperCase()
                                                                                                }
                                                                                            </span>
                                                                                }
                                                                            </b>
                                                                        </span>
                                                                    ) : null
                                                                }
                                                                {
                                                                    field.type === "toggleCheckbox" ?
                                                                        field.options.map((item: any, k: number) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        reportIpRef.template[item.key] ?
                                                                                            <span className="fds-tag info ms-0 mb-1">
                                                                                                {item.value}:
                                                                                                <b>{reportIpRef.template[item.key] ?
                                                                                                    (<i className="fds-glyphs-checkbox-checked2 infoTxt" />) :
                                                                                                    (<i className="fds-glyphs-cancel-square2 dangerTxt" />)
                                                                                                }</b>
                                                                                            </span> :
                                                                                            null
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }) : null
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        })
                                    })}
                                </div> : null
                            }
                        </Row>
                        {templates[props.params].lists.length > 1 ? <div className="backMenu">
                            <ul>
                                {[...templates[props.params].lists].map((list: any, i: number) => {
                                    if (!list.showFor || customersRef?.customer?.data_content?.indexOf(list.showFor.subscription) >= 0) {
                                        return (<li key={i} onClick={() => {
                                            window.document.querySelectorAll(".minBtn")[0]?.click();
                                            window.document.querySelectorAll(".arrowList .list-group-item")[i]?.click();
                                        }}><span className={`${['', 'success', 'info', 'warning'][i]}Txt`}><i className='fds-glyphs-pencil3'></i></span> {list.label}</li>)
                                    }
                                    return null
                                })}
                            </ul>
                        </div> : null}

                        {showResults()}
                    </div> :
                    <div className='resultsLoading pt-3'>
                        {props.onLoadRun ?
                            <>
                                {
                                    isError !== 0 && reportIpRef.executionType !== "D" ?
                                        <div className='pt-3'>
                                            <Spinner animation="border" role="status" variant="primary">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            <span className='ms-3' style={{ position: "absolute", marginTop: "3px" }}>Processing...</span>
                                        </div> : null
                                }

                                {showResults()}
                            </> :
                            <div>
                                {
                                    Object.keys(data).length > 0 && props.params === "ARCCIDS" ?
                                        <ARCCIDataStatus data={data} /> :
                                        <div id="emptyResultWrap">
                                            <i className='icon mb-3 fds-glyphs-airplane' />
                                            <div><b>Narrow Down Your Results Using The Filters</b></div>
                                        </div>
                                }
                            </div>
                        }
                    </div>
                }
            </Col>
        </React.Fragment >
    );
};