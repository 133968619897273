declare const window: any;

const thisYear = new Date().getFullYear();

export const CarrierDashboardTemplate: any = {
    reportName: "CARRIER_DASHBOARD",
    onLoadRun: true,                // Runs report on initial report open
    filtersOnTop: true,             // Align filters on top instead of on left side
    darkBackground: true,           // For dark report background
    hideAllTopFilterOptions: true,  // Hides all buttons, saved template, clear all buttons from filters section that are on top
    onChangeSubmit: true,           // On change of filter value, report runs
    checkTrendOptions: ["saleTrends"],
    info: "The following provides processing statistics, by calendar month, for the last three years plus the current year-to-date. If you have questions, please contact your account manager.",
    lists: [{
        label: "Query Filters",
        colorName: "",
        wraps: [{
            name: "airline",
            showName: false,
            excludable: true,
            fields: [{
                name: "airline",
                label: "Airline(s)",
                rLabel: "Airline code",
                help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                minChar: 0,
                url: window.ENV.references.airline,
                outputLabel: true,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airline) || '[]'),
                placeholder: "Enter Airline(s)"
            }]
        }, {
            name: "Period Date Range",
            showName: true,
            type: 'option',
            condition: 'OR',
            help: "Data is refreshed weekly",
            fields: [{
                name: "month_report_period",
                tab: "By Month",
                label: "By Month or Range",
                type: "monthYearPicker",
                selectsRange: true,
                startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                endDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                show: true
            }, {
                name: "quarter_report_period",
                tab: "By Quarter",
                label: "By Quarter or Range",
                type: "quarterPicker",
                selectsRange: true,
                startDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                endDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                show: false
            }]
        }]
    }],
    chartsLists: [
        {
            label: "",
            wraps: [
                {
                    name: "performance_metrics",
                    label: "Performance Metrics",
                    showName: false,
                    fields: [{
                        name: "marketShare",
                        label: "marketShare",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "SummaryCards",
                        keys: {
                            label: "summary",
                            values: [
                                "transactions",
                                "sales",
                                "average_sales"
                            ],
                            extensionAllowed: [true, true, true],
                            multiValueExtensions: [{
                                key: "_all",
                                label: "All Carriers"
                            }, {
                                key: "",
                                label: "Carrier"
                            }],
                            vLabels: ["Transactions", "Sales", "Average Sale"],
                            symbol: ["", "$", "$"]
                        }
                    }]
                }, {
                    name: "performance_metrics",
                    label: "Performance Metrics",
                    showName: false,
                    fields: [{
                        name: "marketShare",
                        label: "marketShare",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "SummaryCards",
                        keys: {
                            label: "summary",
                            values: [
                                "commission_amount",
                                "cash_savings"
                            ],
                            extensionAllowed: [false, false],
                            multiValueExtensions: [{
                                key: "",
                                label: "Carrier"
                            }, {
                                key: "_all",
                                label: "All Carriers"
                            }],
                            vLabels: ["Commission Paid", "Estimated Cash Savings"],
                            symbol: ["$", "$", "%", "%"]
                        },
                        spacing: 8
                    }, {
                        name: "marketShare",
                        label: "Ticket Market Share",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "BarPercentChart",
                        keys: {
                            label: "summary",
                            values: ["ticketMarketShare"],
                            symbol: "$",
                            sort: ""
                        },
                        spacing: 4
                    }]
                }, {
                    name: "saleTrends",
                    label: "Sale & Transacation Trends",
                    showName: true,
                    compareTrendOptions: [thisYear - 2, thisYear - 1, thisYear],
                    fields: [{
                        name: "trends",
                        label: "Sale Trends",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "XYChart",
                        enableLines: true,
                        enableDots: true,
                        enableArea: true,
                        enableValues: false,
                        labels: { x: "Timeframe", y: "Sales" },
                        serviceKeys: {
                            label: "month",
                            values: ["sValue"]
                        },
                        keys: {
                            label: "month",
                            values: [],
                            legends: [],
                            legendToAppend: "Sales in ",
                            headers: {},
                            symbol: "$"
                        },
                        spacing: 6
                    }, {
                        name: "trends",
                        label: "Transaction Trends",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "XYChart",
                        enableLines: true,
                        enableDots: true,
                        enableArea: true,
                        enableValues: false,
                        labels: { x: "Timeframe", y: "Transactions" },
                        serviceKeys: {
                            label: "month",
                            values: ["tValue"],
                        },
                        keys: {
                            label: "month",
                            values: [],
                            legends: [],
                            legendToAppend: "Transactions in ",
                            headers: {
                                blended_pay: "Total Actual Payment Amount"
                            },
                            symbol: "$"
                        },
                        spacing: 6
                    }]
                }, {
                    name: "awawerqwer",
                    label: "sdf",
                    showName: false,
                    fields: [{
                        name: "creditCardSalesByBrand",
                        label: "Credit Card Sales",
                        help: "",
                        type: "BarChart",
                        orientation: "H",
                        labels: { x: "Sales", y: "Credit Card" },
                        keys: {
                            label: "creditCardCompanyAbbr",
                            values: ["tranAmt"],
                            legends: ["Sales Amount"],
                            symbol: "$",
                            sort: ""
                        },
                        spacing: 8
                    }, {
                        name: "creditCash",
                        label: "Transactions",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "DonutChart",
                        showTotal: true,
                        totalText: "Total",
                        theme: { colors: ['#189BB0', "#75E6DA", "#D4F1F4"] },
                        keys: {
                            label: "type",
                            value: "value",
                            symbol: "$",
                            sort: ""
                        },
                        spacing: 4
                    }]
                }, {
                    name: "awawerasdqwer",
                    label: "sdf",
                    showName: false,
                    fields: [{
                        name: "gds",
                        label: "GDS Distribution",
                        help: "",
                        type: "BarChart",
                        orientation: "V",
                        labels: { x: "GDS", y: "Sales" },
                        keys: {
                            label: "label",
                            values: ["transaction", "distribution"],
                            legends: ["Transaction", "Distribution"],
                            symbol: "$",
                            sort: ""
                        },
                        spacing: 6
                    }, {
                        name: "credit",
                        label: "Credit Card Sales",
                        help: "",
                        type: "BarChart",
                        orientation: "V",
                        labels: { x: "Credit Cards", y: "Sales" },
                        keys: {
                            label: "label",
                            values: ["transaction", "distribution"],
                            legends: ["Transaction", "Distribution"],
                            symbol: "$",
                            sort: ""
                        },
                        spacing: 6
                    }]
                }, {
                    name: "asas",
                    label: "sdf",
                    showName: false,
                    fields: [{
                        name: "transactionTypes",
                        label: "Transaction Types",
                        help: "",
                        type: "CirclePackageChart",
                        labels: { x: "Sales", y: "Credit Card" },
                        keys: {
                            label: "type",
                            value: "value",
                            symbol: "$",
                            sort: ""
                        },
                        spacing: 6
                    }]
                }, {
                    name: "Top Agency",
                    label: "sdf",
                    showName: false,
                    fields: [{
                        name: "topAgencies",
                        label: "Top Agencies",
                        help: "",
                        type: "BarChart",
                        orientation: "H",
                        labelIndex: true,
                        labels: { x: "Sales", y: "Agency" },
                        keys: {
                            label: "agency",
                            values: ["cyTotalSales"],
                            legends: ["Sales Amount"],
                            symbol: "$",
                            sort: "cyTotalSales"
                        },
                        spacing: 12
                    }]
                }
            ]
        }
    ]
};

