import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Table, Alert } from "react-bootstrap";
import { numberFormat, numberFormatTxt, valueOrDash, isDefined } from '@arctravel/react-fds';
import axios from "axios";

interface HCell {
  count: number | 0,
  color: string | ''
}

interface FDSPivoteProps {
  rows: HCell,
  cols: HCell
}

interface FDSInfiniteTableProps {
  pageRecordCount: number,
  apiUrl: string,
  token: string,
  data: any,
  header?: any,
  headerOnly: boolean | false,
  borderedHeader?: boolean | false,
  showTooltips: boolean,
  sort?: boolean | false,
  totalLine?: any[] | [],
  totalLinePosition?: "TOP" | "BOTTOM" | "ALL",
  compacted: boolean,
  pivote?: FDSPivoteProps
}

interface FDSInfiniteTableConfigProps {
  config: FDSInfiniteTableProps,
  onChange: any,
  onSort?: any
}

let ST = 0;
let CP = 0, UP = 0;
const fontWidth = 16;
const initTxt = "Initializing...";

export const FDSInfiniteTable = (props: FDSInfiniteTableConfigProps) => {
  const rowCount: number = props.config.pageRecordCount;
  const apiUrl: string = props.config.apiUrl;
  const configData: any = props.config.data;
  const token: string = props.config.token;
  const onChange: any = props.onChange;

  const [tData, setTData]: any[] = useState([{ table: initTxt }]);
  const [pageFrom, setPageFrom] = useState(0);
  const [pageTo, setPageTo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const tbodyRef = useRef<any>(null);
  const [sortId, setSortId] = useState(-1);
  const totalRef = useRef(1)
  const rowCountRef = useRef(rowCount)
  const hasOnChangeAction = useRef(false);

  const getData = useCallback((i: number) => {
    setIsLoading(true);

    return axios.post(apiUrl, { ...configData, pageIndex: i }, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    });
  }, [apiUrl, configData, token]);

  const getRowHeight = () => {
    return tbodyRef.current?.children[1]?.clientHeight;
  }


  const scrolled = useCallback((ev: any) => {
    let timer: any = null;
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      const T = tbodyRef?.current?.scrollTop;

      const RAW_UP = T / getRowHeight() / rowCount;
      UP = Math.floor(RAW_UP);
      const DIFF = CP - UP;

      let UD = ev ? "" : "NULL";
      if (((ST - T > 0 && DIFF > 0) || (ST - T > 0 && DIFF === 0 && RAW_UP - UP < .25)) && (CP !== 0)) {
        UD = "UP";
        CP = (DIFF === 0 && UP > 2 ? CP - 2 : UP);
      } else if (DIFF < -2 || (DIFF === -2 && RAW_UP - UP > .25)) {
        UD = "DOWN";
        CP = UP;
      }

      ST = T;

      if (UD.length > 0 && CP <= totalRef.current / rowCountRef.current) {
        Promise.all([
          getData(CP),
          getData(CP + 1),
          getData(CP + 2)
        ]).then(responses => {
          console.log("responses---", responses)
          setIsLoading(false);

          const r0 = responses[0].data.results;
          const r1 = responses[1].data.results;
          const r2 = responses[2].data.results;
          setTData([...r0, ...r1, ...r2]);
          setTimeout(() => {
            hasOnChangeAction.current = true

            const totalRecords = responses[0].data.totalRecords;
            totalRef.current = totalRecords
            // setTotal(totalRecords);

            const TOTAL = totalRecords * getRowHeight();
            const HF = CP * rowCount * getRowHeight();
            const M = (tbodyRef.current?.children?.length - 2) * getRowHeight();
            const HL = TOTAL - HF - M;

            const last = tbodyRef.current?.children?.length - 1;

            if (tbodyRef.current) {
              tbodyRef.current.children[last].style.height = HL + "px";
              tbodyRef.current.children[0].style.height = HF + "px";
              tbodyRef.current.scrollTop = T;
            }

            setPageFrom(CP * rowCount + 1);
            setPageTo(CP * rowCount + last - 1);
          }, 500);
        }).catch((err: any) => {
          setTData([]);
          hasOnChangeAction.current = true
        });
      }
      return T;
    }, 1000);
  }, [getData, rowCount]);

  const sortTable = (ev: any, i: number) => {
    try {
      ev.persist();
    } catch (ex) { console.log(" "); }

    setSortId(i);

    setTimeout(() => {
      const sortIcon = document.querySelector("#sortIcon");

      sortIcon?.classList.toggle("fds-glyphs-arrow-up5");
      sortIcon?.classList.toggle("fds-glyphs-arrow-down5");

      if (sortIcon) {
        const typeTxt = sortIcon.classList.toString().indexOf("down") > 0 ? "DESC" : "ASC";
        const sortObj = { sort_index: i, sort_type: typeTxt };
        props.onSort(sortObj)
      }
    }, 500);
  }

  useEffect(() => {
    if (!hasOnChangeAction.current) {
      onChange({ status: "SUCCESS" });
    }
  }, [hasOnChangeAction, onChange])

  useEffect(() => {
    setTData([{ table: initTxt }]);
    scrolled(null);
  }, [scrolled]);

  return (
    <Row className={`fds-table-wrap ${tData.length <= 0 ? "empty" : ""}`}>
      <Col>
        {tData.length > 0 ?
          <React.Fragment>
            <div className="ps-3 pt-3 pe-3 pb-2">
              {!isLoading ? (<div>Viewing <b>{numberFormat(pageFrom, null, null, false)} - {numberFormat(pageTo, null, null, false)}</b> records out of <b>{numberFormat(totalRef.current, null, null, false)}</b> records</div>) : <div>Loading...</div>}
            </div>

            <Table className={`fds-table infinite ${props.config.compacted ? 'sm' : ''}`}>
              <thead className={props.config.borderedHeader ? 'bordered' : ''}>
                {props.config?.headerOnly && props.config.header && Object.keys(props.config.header).length > 0 ?
                  Object.keys(props.config.header).map((row, ri) => {
                    return (<tr key={row}>
                      {Object.keys(props.config.header[row]).map((col, i) => {
                        let width = 0;
                        const C: any = props.config.header[row][col];

                        Object.keys(tData[0]).forEach((col1: string, j: number) => {
                          if (j >= parseInt(col) && j < (parseInt(col) + C.length)) {
                            width += Math.max(col1.replace(/\s/gi, "").length * fontWidth + 20 - (col1.length * 3), 120)
                          }
                        });

                        return (<th
                          className={`${(props?.config?.pivote?.cols?.count || -1) > ri ?
                            props.config.pivote?.cols.color :
                            ((props?.config?.pivote?.cols?.count || 1000000) <= ri && (props.config.pivote?.rows.count || -1) > i) ?
                              props.config.pivote?.rows.color : 'success'}PivotBg`}
                          key={i} colSpan={C.length - 1}
                          style={{
                            width: width + 'px',
                            textAlign: C.format === undefined || C.format === null ? 'left' : 'right'
                          }}
                          title={C.label}
                          onClick={(ev: any) => {
                            if (props.config.sort && Object.keys(props.config.header).length - 1 === ri) {
                              sortTable(ev, i)
                            }
                          }}>
                          <span className="sortLabel">{C.label}</span>
                          {props.config.sort && Object.keys(props.config.header).length - 1 === ri && sortId === i ? <i id="sortIcon" className="fds-glyphs-arrow-up5" /> : null}
                        </th>)
                      })}
                    </tr>)
                  })
                  : null}
                {!props.config.headerOnly ? <tr>
                  {
                    Object.keys(tData[0]).map((col, i) => {
                      return (<th key={i}
                        style={{
                          width: `${Math.max(col.replace(/\s/gi, "").length * fontWidth + 20 - (col.length * 3), 120)}px`,
                          textAlign: typeof tData[0][col] === "string" ? 'left' : 'right'
                        }}
                        onClick={(ev: any) => { sortTable(ev, i) }}>
                        {col.toUpperCase()}
                        {props.config.sort && sortId === i ? <i id="sortIcon" className="fds-glyphs-arrow-up5 ms-10" /> : null}
                      </th>);
                    })
                  }
                </tr> : null}
                {(props.config?.totalLine || []).length > 0 && props.config.totalLinePosition !== "BOTTOM" ?
                  <tr>
                    {props.config?.totalLine?.map((line: any, li: number) => {
                      const col1 = Object.keys(tData[0])?.[li] || "";
                      const col1Val = line || line === 0 ? line : "";
                      const width = Math.max(col1.replace(/\s/gi, "").length * fontWidth + 20 - (col1.length * 3), 120)
                      const formattedLine = isDefined(line) ?
                        numberFormat(line *
                          (
                            props.config.header &&
                              Object.keys(props.config.header).length > 0 &&
                              props.config?.header[Object.keys(props.config?.header).length - 1][li]?.format === "%" ? 100 : 1
                          ),
                          2,
                          props.config.header &&
                            Object.keys(props.config.header).length > 0 ?
                            props.config?.header[Object.keys(props.config?.header).length - 1][li]?.format :
                            "", false
                        ) :
                        valueOrDash(null);

                      const styles: any = { width: width + 'px', textAlign: typeof col1Val === "string" ? 'left' : 'right' };

                      return (<th
                        className="totalLine"
                        title={isDefined(line) ?
                          numberFormatTxt(line *
                            (
                              props.config.header &&
                                Object.keys(props.config.header).length > 0 &&
                                props.config?.header[Object.keys(props.config?.header).length - 1][li]?.format === "%" ? 100 : 1
                            ),
                            2,
                            props.config.header &&
                              Object.keys(props.config.header).length > 0 ?
                              props.config?.header[Object.keys(props.config?.header).length - 1][li]?.format :
                              ""
                          ) :
                          valueOrDash(null)
                        }
                        style={{ ...styles }}
                        key={li}>
                        {typeof line === "string" ?
                          line :
                          <span style={{ textAlign: 'right' }}>{formattedLine}</span>
                        }
                      </th>)
                    })}
                  </tr>
                  : null}
              </thead>

              <tbody ref={tbodyRef} onScroll={(ev: any) => scrolled(ev)}>
                <tr className="before" style={{ height: '0px' }}></tr>
                {tData.map((d: any, i: number) => {
                  return (
                    <tr id={"R" + i} key={i}>
                      {
                        Object.keys(d).map((k, j) =>
                          <td
                            className={`${props.config?.pivote?.rows?.count || -1 > j ? props.config?.pivote?.rows.color : ''}PivotBg`}
                            key={j}
                            style={{
                              width: `${Math.max(k.replace(/\s/gi, "").length * fontWidth + 20 - (k.length * 3), 120)}px`,
                              textAlign: typeof d[k] === "string" ? 'left' : 'right'
                            }}
                            title={props.config.showTooltips ? (typeof d[k] === "string" ? d[k] : isDefined(d[k]) ? numberFormatTxt(parseFloat(d[k]) * (props.config.header && Object.keys(props.config.header).length > 0 && props.config?.header[Object.keys(props.config?.header).length - 1][j]?.format === "%" ?
                              100 : 1), 2, props.config.header && Object.keys(props.config.header).length > 0 ?
                              props.config?.header[Object.keys(props.config?.header).length - 1][j]?.format : "") : valueOrDash(null)) : null}>
                            {
                              typeof d[k] === "string" ?
                                d[k] :
                                <span style={{ textAlign: 'right' }}>{isDefined(d[k]) ? numberFormat(d[k] * (props.config.header && Object.keys(props.config.header).length > 0 && props.config?.header[Object.keys(props.config?.header).length - 1][j]?.format === "%" ?
                                  100 : 1), 2, props.config.header && Object.keys(props.config.header).length > 0 ?
                                  props.config?.header[Object.keys(props.config?.header).length - 1][j]?.format : "", false) : valueOrDash(null)}</span>
                            }
                          </td>
                        )
                      }
                    </tr>
                  );
                })}
                <tr className="after"></tr>
              </tbody>

              {["BOTTOM", "ALL"].indexOf(props.config?.totalLinePosition || "") >= 0 ?
                <tfoot className={props.config.borderedHeader ? 'bordered' : ''}>
                  {(props.config?.totalLine || [])?.length > 0 ?
                    <tr>
                      {props.config?.totalLine?.map((line: any, li: number) => {
                        const col1 = Object.keys(tData[0])?.[li] || "";
                        const col1Val = line || line === 0 ? line : "";
                        const width = Math.max(col1.replace(/\s/gi, "").length * fontWidth + 20 - (col1.length * 3), 120)
                        const formattedLine = isDefined(line) ?
                          numberFormat(line *
                            (
                              props.config.header &&
                                Object.keys(props.config.header).length > 0 &&
                                props.config?.header[Object.keys(props.config?.header).length - 1][li]?.format === "%" ? 100 : 1
                            ),
                            2,
                            props.config.header &&
                              Object.keys(props.config.header).length > 0 ?
                              props.config?.header[Object.keys(props.config?.header).length - 1][li]?.format :
                              "", false
                          ) :
                          valueOrDash(null);

                        const styles: any = { width: width + 'px', textAlign: typeof col1Val === "string" ? 'left' : 'right' };

                        return (<th
                          className="totalLine"
                          title={isDefined(line) ?
                            numberFormatTxt(line *
                              (
                                props.config.header &&
                                  Object.keys(props.config.header).length > 0 &&
                                  props.config?.header[Object.keys(props.config?.header).length - 1][li]?.format === "%" ? 100 : 1
                              ),
                              2,
                              props.config.header &&
                                Object.keys(props.config.header).length > 0 ?
                                props.config?.header[Object.keys(props.config?.header).length - 1][li]?.format :
                                ""
                            ) :
                            valueOrDash(null)}
                          style={{ ...styles }}
                          key={li}>
                          {typeof line === "string" ?
                            line :
                            <span style={{ textAlign: 'right' }}>{formattedLine}</span>
                          }
                        </th>)
                      })}
                    </tr>
                    : null}
                </tfoot> : null
              }
            </Table>
          </React.Fragment> :
          <Alert className="m-3" variant="info">
            <div className="icon">
              <i className="fds-glyphs-info2"></i>
            </div>
            <div className="text">No records found.</div>
          </Alert>
        }
      </Col>
    </Row >
  );
};
