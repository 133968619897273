import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Accordion, Card, useAccordionButton, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import * as FDS from "@arctravel/react-fds/lib";
import DropdownTreeSelect from "react-dropdown-tree-select";
import 'react-dropdown-tree-select/dist/styles.css';
import { useSelector } from 'react-redux';
import { toTree, refreshActivity, getTreeNodes, getTreeChildren, Const } from '../utils';
import { ui_dependencies } from "../utils/Templates";
import { getOts, isInternalAdmin } from '../../okta/ls';

import './DynamicForm.scss';

declare const window: any;

const aMap: any = {};
const requiredTxt = "Required";
const requiredGroupTxt = "At least one of this type's values is required.";
const EXCLUDE = "_EXCLUDE_";
const MASK = "_UNMASK_";

interface TemplateProps {
    template: any,
    onChange(data: any): any,
    customer: any
}

const colors: any = {
    al: "primary",
    ap: "secondary",
    r: "success",
    c: "info"
};

const CustomToggle = (props: any) => {
    const decoratedOnClick = useAccordionButton(props.eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <span onClick={decoratedOnClick}>{props.children}</span>
    );
}

export const DynamicForm = (props: TemplateProps) => {
    const state = useSelector((state: any) => state);
    const referenceDataRef = useSelector((state: any) => state.referenceDataRef);
    const referenceLoadedRef = useSelector((state: any) => state.referenceLoadedRef);
    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [agencyTopOnly, setAgencyTopOnly] = useState([]);
    const [agencyHOLs, setAgencyHOLs] = useState([]);

    const dateRanges = referenceDataRef[window.ENV.references.dtperiods];

    const [showName, setShowName] = useState("");
    const [template, setTemplate] = useState({ ...props.template });
    const [, setTemplateName] = useState("")

    const [prevPrior, setPrevPrior] = useState("");

    const checkChecked = (ev: any, wrap: any, field: any, opt: any) => {
        const prev: any = { ...template };

        refreshActivity();

        const wIndex = prev['wraps'].findIndex((w: any) => w.name === wrap.name && w.fields.length > 0);
        const fIndex = prev['wraps'][wIndex]['fields'].findIndex((f: any) => f.name === field.name);
        const oIndex = opt ? prev['wraps'][wIndex]['fields'][fIndex]['options'].findIndex((o: any) => o.key === opt.key) : -1;
        //console.log("fIndex", fIndex, oIndex)
        if (oIndex >= 0) {
            //prev['wraps'][wIndex]['fields'][fIndex]['options'][oIndex]['checked'] = ev.target.checked;
            //prev['wraps'][wIndex]['fields'][fIndex].value[opt.key] = ev.target.checked;
            prev['wraps'][wIndex]['fields'][fIndex].value.map((valObj: any) => {
                return valObj[ev.target.name] = ev.target.checked;
            })
        } else {
            if (fIndex === 0) {
                prev['wraps'][wIndex]['fields'].forEach((field: any, fi: number) => {
                    //console.log(prev['wraps'][wIndex]['fields'])
                    prev['wraps'][wIndex]['fields'][fi].checked = ev.target.checked;

                    prev['wraps'][wIndex]['fields'][fi].value.length = 0;
                    field.options.forEach((op: any) => {
                        const v: any = {};
                        v[op.key] = ev.target.checked;
                        prev['wraps'][wIndex]['fields'][fi].value.push(v);
                    });
                })
            } else {
                prev['wraps'][wIndex]['fields'][fIndex]['checked'] = ev.target.checked;
                prev['wraps'][wIndex]['fields'][fIndex]['options'].forEach((op: any) => {
                    prev['wraps'][wIndex]['fields'][fIndex].value[op.key] = ev.target.checked;
                    //op.checked = ev.target.checked;
                    //return op;
                })
            }
        }

        if (opt) {
            const oCount = prev['wraps'][wIndex]['fields'][fIndex].value.reduce((total: number, valObj: any) => valObj[Object.keys(valObj)[0]] ? total + 1 : total, 0);
            if (oCount === prev['wraps'][wIndex]['fields'][fIndex]['options'].length) {
                prev['wraps'][wIndex]['fields'][fIndex]['checked'] = true;
            } else {
                prev['wraps'][wIndex]['fields'][fIndex]['checked'] = false;
            }
        }

        if (prev['wraps'][wIndex]['fields'].length > 1) {
            let tCount = prev['wraps'][wIndex]['fields'].reduce((total: number, tb: any) => tb.checked ? total + 1 : total, 0);
            if (prev['wraps'][wIndex]['fields'][0]['checked']) {
                tCount--;
            }
            if (tCount === prev['wraps'][wIndex]['fields'].length - 1) {
                prev['wraps'][wIndex]['fields'][0]['checked'] = true;
            } else {
                prev['wraps'][wIndex]['fields'][0]['checked'] = false;
            }
        }

        setTemplate(prev);
        props.onChange(prev);
    }

    const checkDeps = useCallback((prev: any) => {
        let Ti = -1, Pi = -1;
        if (dateRanges && dateRanges[0].DATE_TYPE.toLowerCase().indexOf("purchase") >= 0) {
            Pi = 0;
            Ti = 1;
        } else {
            Pi = 1;
            Ti = 0;
        }

        (ui_dependencies[template.reportName] || []).forEach((dep: any, di: number) => {
            const fld = template.wraps[dep.onChange[0]]?.fields[dep.onChange[1]];

            if (dep.type === "date") {
                const minDt = prev['wraps'][dep.changeThis[0]]?.fields[dep.changeThis[1]]?.minDate ? prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]].minDate : dateRanges ?
                    (fld.value?.indexOf("travel") >= 0 ? dateRanges[Ti]["START_DATE"] :
                        [Const.AIRLINE, Const.AGENCY].indexOf(props.customer?.entity?.toUpperCase()) >= 0 && props.customer.org !== Const.ARC_ROLE ?
                            ((new Date().getMonth() + 1) + "/1/" + (new Date().getFullYear() - (prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]["tab"] === "12M Sum" ? 4 : 5))) :
                            dateRanges[Pi]["START_DATE"]
                    ) : "";
                const maxDt = dateRanges ?
                    (fld.value?.indexOf("travel") >= 0 ?
                        dateRanges[Ti]["END_DATE"] :
                        [Const.AIRLINE, Const.AGENCY].indexOf(props.customer?.entity?.toUpperCase()) >= 0 && props.customer.org !== Const.ARC_ROLE ?
                            ((new Date().getMonth() + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear()) :
                            dateRanges[Pi]["END_DATE"]
                    ) : "";

                if (prev['wraps'][dep.changeThis[0]] && prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]) {
                    prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['minDate'] = minDt;
                    prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['maxDate'] = maxDt;
                }
            }

            if (prevPrior.length === 0 && fld?.value?.length === 1 && fld?.value !== "Y") {
                // For Agency BI only to enable tab clicks when Prior M is selected
                setPrevPrior(fld.value);
            }
            if (dep.type === "date_prior" && prevPrior !== fld?.value) {
                setPrevPrior(fld.value);
                if (fld.value === "M") {
                    if (prev['wraps'][dep.changeThis[0]] && prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]) {
                        prev['wraps'][dep.changeThis[0]]['fields'][0]['disabled'] = true;
                        prev['wraps'][dep.changeThis[0]]['fields'][0]['show'] = false;
                        prev['wraps'][dep.changeThis[0]]['fields'][1]['disabled'] = false;
                        prev['wraps'][dep.changeThis[0]]['fields'][2]['disabled'] = false;
                        
                        prev['wraps'][dep.changeThis[0]]['fields'][3]['disabled'] = true;
                        prev['wraps'][dep.changeThis[0]]['fields'][4]['disabled'] = true;
                        prev['wraps'][dep.changeThis[0]]['fields'][3]['show'] = false;
                        prev['wraps'][dep.changeThis[0]]['fields'][4]['show'] = false;
                    }
                } else if (fld.value === "Y") {
                    if (prev['wraps'][dep.changeThis[0]] && prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]) {
                        prev['wraps'][dep.changeThis[0]]['fields'][0]['disabled'] = false;
                        prev['wraps'][dep.changeThis[0]]['fields'][2]['disabled'] = false;
                        prev['wraps'][dep.changeThis[0]]['fields'][3]['disabled'] = false;
                        prev['wraps'][dep.changeThis[0]]['fields'][4]['disabled'] = false;
                    }
                } else if (fld.value === "W") {
                    prev['wraps'][dep.changeThis[0]]['fields'][0]['disabled'] = true;
                    prev['wraps'][dep.changeThis[0]]['fields'][2]['disabled'] = true;
                    prev['wraps'][dep.changeThis[0]]['fields'][3]['disabled'] = true;
                    prev['wraps'][dep.changeThis[0]]['fields'][4]['disabled'] = true;

                    prev['wraps'][dep.changeThis[0]]['fields'][0]['show'] = false;
                    prev['wraps'][dep.changeThis[0]]['fields'][1]['show'] = true;
                    prev['wraps'][dep.changeThis[0]]['fields'][2]['show'] = false;
                    prev['wraps'][dep.changeThis[0]]['fields'][3]['show'] = false;
                    prev['wraps'][dep.changeThis[0]]['fields'][4]['show'] = false;
                }
            }

            if (dep.type === "purchase_date") {
                const minDt = dateRanges ?
                    [Const.AIRLINE, Const.AGENCY].indexOf(props.customer?.entity?.toUpperCase()) >= 0 && props.customer.org !== Const.ARC_ROLE ?
                        ((new Date().getMonth() + 1) + "/1/" + (new Date().getFullYear() - (dep.changeThis[1] === 5 ? 4 : 5))) :
                        dateRanges[Pi]["START_DATE"] :
                    "";
                const maxDt = dateRanges ?
                    [Const.AIRLINE, Const.AGENCY].indexOf(props.customer?.entity?.toUpperCase()) >= 0 && props.customer.org !== Const.ARC_ROLE ?
                        ((new Date().getMonth() + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear()) :
                        dateRanges[Pi]["END_DATE"] :
                    "";

                if (prev['wraps'][dep.changeThis[0]] && prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]) {
                    prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['minDate'] = minDt;
                    prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['maxDate'] = maxDt;
                }
            }

            if (dep.type === "travel_date") {
                const minDt = dateRanges ? dateRanges[Ti]["START_DATE"] : "";
                const maxDt = dateRanges ? dateRanges[Ti]["END_DATE"] : "";

                if (prev['wraps'][dep.changeThis[0]] && prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]) {
                    prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['minDate'] = minDt;
                    prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['maxDate'] = maxDt;
                }
            }

            if (dep.type === "source" && prev['wraps'][dep.changeThis[0]]) {
                const field = prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]];
                if (field) {
                    if (fld && Array.isArray(fld['value']) && fld['value']?.filter((v: any) => ["1", "2"].indexOf(v[fld.codeKey]) >= 0).length > 0) {
                        prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['options'] = prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['options']?.map((opt: any) => opt[field.codeKey] === "O" || opt?.hide ? { ...opt, hide: true } : { ...opt, hide: false });
                        if ([...prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['value']]?.map((opt: any) => opt[field.codeKey]).indexOf("O") >= 0) {
                            prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['value'] = prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['value'].filter((opt: any) => opt[field.codeKey] !== "O");
                        }
                    } else {
                        prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['options'] = prev['wraps'][dep.changeThis[0]]['fields'][dep.changeThis[1]]['options'].map((opt: any) => { return { ...opt, hide: false } });
                    }
                }
            }
        });
        return prev;
    }, [dateRanges, template.reportName, template.wraps, prevPrior, props.customer]);

    let inputTypingTimer: any;

    const inputChange = (ev: any, wrap: any, field: any) => {
        let prev: any = { ...template };

        refreshActivity();

        const wIndex = prev['wraps'].findIndex((w: any) => w.name === wrap.name && w.fields.length > 0);
        const fIndex = prev['wraps'][wIndex]['fields'].findIndex((f: any) => f.name === field.name);

        if (((wrap.excludable && ev.target.name?.indexOf(EXCLUDE) > 0) || (wrap.masking && ev.target.name?.indexOf(MASK) > 0)) && (!wrap.checks || (wrap.checks && props.customer.data_content.indexOf(wrap.checks) < 0))) {
            prev['wraps'][wIndex][wrap.masking ? "unmask" : "exclude"] = ev.target.checked;
        } else {
            if (ev.target.value.startDate) {
                prev['wraps'][wIndex]['fields'][fIndex] = { ...prev['wraps'][wIndex]['fields'][fIndex], ...ev.target.value };
            } else if (typeof ev.target.value !== 'string') {
                [...JSON.parse(JSON.stringify(ev.target.value))]?.forEach((obj: any) => {
                    obj[field.codeKey]?.split(",").forEach((c: string) => {
                        //TOND/JI
                        if (field.store === "groupingsDataRef" && c.length === 6) {
                            const a1 = c.substring(0, 3);
                            const a2 = c.substring(3, 6);
                            if (!aMap[a1] || !aMap[a2]) {
                                c = "";
                            }
                        } else if (field.store === "groupingsDataRef") {
                            c = "";
                        }

                        if (c.length > 0) {
                            obj[field.codeKey] = c;// + (obj['region_type'] ? ":" + obj['region_type'] : "");

                            if (field.type === "tree" && !field.url) {
                                const ls = getTreeNodes(field, field.options);
                                const rcMap: any = {};
                                // Convert to map from options
                                for (let i = 0; i < ls?.length; i++) {
                                    if (!ls[i][field.codeKey]) {
                                        continue;
                                    }
                                    rcMap[ls[i][field.codeKey]] = ls[i];
                                }

                                obj[field.labelKey] = rcMap[c][field.labelKey];
                            }

                            prev['wraps'][wIndex]['fields'][fIndex]['value'] = [...(field.hasOwnProperty('clearOnSelect') && field.clearOnSelect === false ? [] : prev['wraps'][wIndex]['fields'][fIndex]['value']), ...[{ ...obj }]]
                                .filter((item, pos, self) => item[field.codeKey] && (!wrap.checks || field.options.find((e: any) => e[field.codeKey] === item[field.codeKey])) && pos === self.findIndex((t) => (
                                    t[field.codeKey] === item[field.codeKey]
                                )))
                        }
                    });
                })
            } else if (field.type === "checkbox") {
                if (ev.target.checked) {
                    if (prev['wraps'][wIndex]['fields'][fIndex]['value'].indexOf(ev.target.value) < 0) {
                        prev['wraps'][wIndex]['fields'][fIndex]['value'].push(ev.target.value);
                    }
                } else {
                    prev['wraps'][wIndex]['fields'][fIndex]['value'] = prev['wraps'][wIndex]['fields'][fIndex]['value'].filter((v: any) => v !== ev.target.value);
                }
            } else {
                console.log("FFF", ev.target.value)
                prev['wraps'][wIndex]['fields'][fIndex]['value'] = ev.target.value;
            }
        }

        prev = checkDeps(prev);

        setTemplate(prev);

        clearTimeout(inputTypingTimer);
        inputTypingTimer = setTimeout(() => {
            props.onChange(prev);
        }, 500);
    }

    const inputChangeRemove = (val: any, wrap: any, field: any) => {
        const prev = { ...template };

        const wIndex = prev['wraps'].findIndex((w: any) => w.name === wrap.name && w.fields.length > 0);
        const fIndex = prev['wraps'][wIndex]['fields'].findIndex((f: any) => f.name === field.name);

        if (val === "CLEAR_ALL") {
            prev['wraps'][wIndex]['fields'][fIndex]['value'].length = 0;
        } else {
            const lst = prev['wraps'][wIndex]['fields'][fIndex]['value'];
            prev['wraps'][wIndex]['fields'][fIndex]['value'] = lst.filter((item: any) => item[field.codeKey] !== val[field.codeKey]);
        }

        setTemplate(prev);
        props.onChange(prev);
    }

    const checkDisable = (wrap: any, field: any, opt: any, i: number) => {
        if (template.wraps[12]?.fields[0]?.value === opt.key.replace("show_", "")) {
            field.value[i][opt.key] = true;
            if (field?.value.filter((v: any) => v[Object.keys(v)[0]] === false).length === 0) {
                field.checked = true;
            }
            return true;
        } else if (field.options[i]?.disabled === true) {
            field.value[i][opt.key] = false;
            return true
        } else {
            return false;
        }
    }

    const renderAccordionWithCheckbox = (wrap: any, field: any, index: number) => {
        return (
            <Card className="basic no-shadow enable-border no-radius" key={index}>
                <Card.Header as={Row}>
                    <Row className="ps-0 mb-0 me-4">
                        <Col sm={4} md={3} style={{ width: "200px" }}>{field.withCheckbox ? <Form.Check type="checkbox" data-testid={field.name} key={field.name} id={field.name} name={field.name} label={field.label} style={{ width: "300px" }} onChange={(ev) => checkChecked(ev, wrap, field, null)} checked={field.checked} /> : field.label}</Col>
                        <Col>
                            <CustomToggle eventKey={index.toString()} onClick={() => { setShowName(index === 0 ? field.name : "") }}>
                                {index === 0 && field.collapse ? <> <span className={`${field.name} cursor viewall`}>View All</span> <i className={`${field.name} cursor fds-glyphs-arrow-down3`} /></> : ''}
                            </CustomToggle>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion.Collapse className={showName === field.name ? 'show' : ''} eventKey={index.toString()}>
                    <Card.Body className={field.options.length > 0 ? "ps-3" : "p-0"}>
                        {
                            field.options?.map((opt: any, i: number) => {
                                if (opt.disableFor && (opt.disableFor.entity.indexOf(props.customer.entity) >= 0 || props.customer.data_content.indexOf(opt.disableFor?.subscription) >= 0)) opt.disabled = true
                                if (["THIRDPARTY-FINANCIAL"].indexOf(props.customer.entity) >= 0 && opt.key === "show_countries") field.value[i][opt.key] = true
                                return (
                                    <Form.Check
                                        className={`ms-3 ${opt.disableFor && (opt.disableFor.entity.indexOf(props.customer.entity) >= 0 || props.customer.data_content.indexOf(opt.disableFor?.subscription) >= 0) ? "hide" : ""}`}
                                        type="checkbox"
                                        id={opt?.key}
                                        key={opt?.key}
                                        name={opt?.key}
                                        label={opt.value}
                                        disabled={checkDisable(wrap, field, opt, i)}
                                        onChange={(ev) => checkChecked(ev, wrap, field, opt)}
                                        checked={field.value[i]?.[opt.key]} />
                                )
                            })
                        }
                    </Card.Body>
                </Accordion.Collapse>

            </Card>
        );
    }

    let treeVals: any = [];
    const onChange = (currentNode: any, selectedNodes: any, wrap: any, field: any) => {
        treeVals = [...selectedNodes];
    }
    const onBlur = (wrap: any, field: any) => {
        //console.log('onBlur::', treeVals);
        inputChange({ target: { value: treeVals } }, wrap, field)
    }

    const getTagWrap = (wrap: any) => {
        return (<div className={`tagWrap`}>
            {wrap.fields.map((field: any, j: number) => {
                return (
                    <React.Fragment key={j}>
                        {!field.disabled && (!field.disableFor || field.disableFor?.entity.indexOf(props.customer?.entity) < 0) && ["typeahead", "tree"].indexOf(field.type) >= 0 ? getTagField(wrap, field) : null}
                    </React.Fragment>
                )
            })}
        </div>);
    }

    const getTagField = (wrap: any, field: any) => {
        return (<div key={field.name} className={field.disabled && ["typeahead", "tree"].indexOf(field.type) >= 0 ? 'tagWrap form-control disabled' : (field.individual ? 'tagWrap individual' : '')}>
            {(((wrap.condition === "OR" && field.show) || (wrap.condition === "AND") || (!wrap.hasOwnProperty('condition')))) &&
                field.value && typeof field.value != "string" && ["typeahead", "tree"].indexOf(field.type) >= 0 ?
                <React.Fragment>
                    <div className={!field.disabled && ["typeahead", "tree"].indexOf(field.type) >= 0 && field.value?.length > 0 ? "pt-2" : ""}>
                        {
                            !field.disabled && ["typeahead", "tree"].indexOf(field.type) >= 0 && field.value?.length > 0 ?
                                <label className="selection pe-3">{field.label} :</label> :
                                null
                        }

                        {
                            ["typeahead", "tree"].indexOf(field.type) >= 0 && field.value?.length > 1 && !field.disabled ?
                                <a href="/#" onClick={(ev) => {
                                    ev.preventDefault();
                                    inputChangeRemove("CLEAR_ALL", wrap, field);
                                }}><small style={{ fontSize: '1rem' }}>Clear Selected</small></a> :
                                null
                        }
                    </div>
                    {
                        field.value
                            .filter((v: any) => {
                                if (template.reportName === 'INTERACTIVE' && field.type === "tree" && field.treeChildrenCheck) {
                                    return (field.noTop ? field.options.filter((op: any) => !op.top) : getTreeChildren(props.customer, field.options))
                                        .filter((o: any) => o.key?.split(",").indexOf(v.key) >= 0).length > 0;
                                } else {
                                    return true;
                                }
                            })
                            .map((v: any, i: number) => {
                                return (
                                    <div className={`fds-tag ${colors[field.color] ? colors[field.color] : ''} mt-2 mb-1`} key={i + 200}>
                                        {
                                            v[field.labelKey] ?
                                                FDS.valueOrDash(v[field.labelKey]) :
                                                field.options.filter((opt: any) => opt[field.codeKey] === v[field.codeKey])[0]?.[field.labelKey]
                                        }
                                        {
                                            v[field.labelKey] !== v[field.codeKey] ?
                                                (v[field.codeKey].indexOf(":") > 0 ?
                                                    (v[field.labelKey] ? "(" : "") + v[field.codeKey].substring(0, v[field.codeKey].indexOf(":")) + (v[field.labelKey] ? ")" : "") :
                                                    (v[field.labelKey] || (field.options.filter((opt: any) => opt[field.codeKey] === v[field.codeKey])[0]?.[field.labelKey]) ? "(" : "") + v[field.codeKey] + (v[field.labelKey] || field.options.filter((opt: any) => opt[field.codeKey] === v[field.codeKey])[0]?.[field.labelKey] ? ")" : "")) :
                                                null
                                        }
                                        {!field.disabled ? <i className="fds-glyphs-clear" onClick={() => inputChangeRemove(v, wrap, field)}></i> : null}
                                    </div>
                                )
                            })}
                </React.Fragment> : null}
        </div>);
    }

    const getTab = (wrap: any) => {
        return (<div className="tab-content">
            <div style={{ display: 'flex' }}>
                {wrap.fields.map((field: any, j: number) => {
                    let renRes = null;
                    switch (field.type) {
                        case "date":
                            renRes = (
                                <FDS.FDSDatePicker
                                    data-testid={field.name}
                                    startDate={[field.startDate[0]]}
                                    endDate={[field.endDate[0]]}
                                    minDate={field.minDate}
                                    maxDate={field.maxDate}
                                    selectsRange={field.selectsRange}
                                    showWeekPicker={field.subType === "week"}
                                    compare={field.compare}
                                    onChange={(value) => inputChange({ target: { value: value } }, wrap, field)} />
                            );
                            break;
                        case "monthYearPicker":
                            renRes = (
                                <FDS.FDSDatePicker
                                    data-testid={field.name}
                                    startDate={field.compare ? [field.startDate[0].replace(/Q/ig, ""), field.startDate[1].replace(/Q/ig, "")] : [field.startDate[0].replace(/Q/ig, "")]}
                                    endDate={!field.selectsRange ? [] : field.compare ? [field.endDate[0]?.replace(/Q/ig, ""), field.endDate[1]?.replace(/Q/ig, "")] : [field.endDate[0]?.replace(/Q/ig, "")]}
                                    minDate={field.minDate}
                                    maxDate={field.maxDate}
                                    selectsRange={field.selectsRange}
                                    showMonthYearPicker={true}
                                    compare={field.compare}
                                    onChange={(value) => inputChange({ target: { value } }, wrap, field)} />
                            );
                            break;
                        case "quarterPicker":
                            renRes = (
                                <FDS.FDSDatePicker
                                    data-testid={field.name}
                                    startDate={field.compare ? [field.startDate[0], field.startDate[1]] : [field.startDate[0]]}
                                    endDate={field.compare ? [field.endDate[0], field.endDate[1]] : [field.endDate[0]]}
                                    minDate={field.minDate}
                                    maxDate={field.maxDate}
                                    selectsRange={true}
                                    showQuarterYearPicker={true}
                                    compare={field.compare}
                                    onChange={(value) => inputChange({ target: { value } }, wrap, field)} />
                            );
                            break;
                        case "yearPicker":
                            renRes = (
                                <FDS.FDSDatePicker
                                    data-testid={field.name}
                                    startDate={[...field.startDate]}
                                    endDate={[...field.endDate]}
                                    minDate={field.minDate}
                                    maxDate={field.maxDate}
                                    showYearPicker={true}
                                    selectsRange={field.selectsRange}
                                    compare={field.compare}
                                    onChange={(value) => inputChange({ target: { value } }, wrap, field)} />
                            );
                            break;
                        case "select":
                            renRes = (
                                <div className="select">
                                    <Form.Select aria-label={`select ${field.name}`} key={j} id={field.name} data-testid={field.name} name={field.name} disabled={field.disabled} onChange={(ev) => inputChange(ev, wrap, field)} value={field.value}>
                                        {!field.noSelect ? <option value="">--Select--</option> : null}
                                        {field.options?.map((opt: any, o: number) => {
                                            return (
                                                <React.Fragment key={o}>
                                                    {opt.checked && !opt.disabled && (opt.disableFor && opt.disableFor.entity.indexOf(props.customer.entity) >= 0 ? false : true) ? <option key={o} value={opt.key}>{opt.value}</option> : null}
                                                </React.Fragment>
                                            )
                                        })}
                                    </Form.Select>
                                </div>
                            );
                            break;
                        case "typeahead":
                            if (field.enableFor && props.customer?.data_source?.indexOf(field?.enableFor?.dataSource) >= 0) {
                                field.disabled = false;
                            } else if (field?.enableFor?.dataSource === "GLOBAL") {
                                if (field.name === "pos_country" && field.value.length === 0) {
                                    field.value = [{ code: "US", name: "United States" }];
                                }
                                if (field.name === "source") {
                                    field.value = [{ code: "1", description: "ASP Settled Data" }];
                                }
                            } else if (field?.enableFor?.dataSource === "1") {
                                if (field.name === "source") {
                                    field.disabled = false;
                                    field.value = [{ code: "1", description: "ASP Settled Data" }];
                                }
                            }

                            if (isInternalAdmin() && customersRef.viewAs.role === Const.INTERNAL_ADMIN && field.name === "source") {
                                // check if dataSource has value other than ASP source
                                const selectedValForSource = field.value.map((val: any) => val[field.codeKey]).every((val: any) => val === "1" || !val.length)
                                if (selectedValForSource) {
                                    wrap.masking = false
                                    wrap.unmask = false
                                } else {
                                    wrap.masking = true
                                }
                            }

                            renRes = (
                                <React.Fragment>
                                    {field.disabled ? <>{getTagField(wrap, field)}</> :
                                        <React.Fragment>
                                            {field.labelKey && field.codeKey ? <div data-testid={field.name}>
                                                <FDS.FDSTypeahead
                                                    key={field.name}
                                                    truncateCodes={true}
                                                    placeholder={field.placeholder}
                                                    dataList={
                                                        (
                                                            referenceLoadedRef.status ?
                                                                (
                                                                    !field.isAgencyTop ?
                                                                        (field.noTop ? field.options.filter((op: any) => !op.top) : field.options.filter((opt: any) => (opt.enableFor && props.customer?.data_source?.indexOf(opt.enableFor.dataSource) >= 0) || !opt.enableFor)) :
                                                                        (field.isAgencyTop ? (field.name === "hol" ? agencyHOLs : agencyTopOnly) : field.options.filter((opt: any) => (opt.enableFor && props.customer?.data_source?.indexOf(opt.enableFor.dataSource) >= 0) || !opt.enableFor))
                                                                ) :
                                                                (field.localStorage || field.options.filter((opt: any) => (opt.enableFor && props.customer?.data_source?.indexOf(opt.enableFor.dataSource) >= 0) || !opt.enableFor))
                                                        ) || []
                                                    }
                                                    selectAll={field.selectAll}
                                                    minChar={field.minChar}
                                                    clearOnSelect={true}
                                                    labelKey={field.labelKey}
                                                    codeKey={field.codeKey}
                                                    hideKey={field.hideKey || false}
                                                    matchedOnly={field.matchedOnly}
                                                    onChange={(value) => inputChange({ target: { value } }, wrap, field)} />
                                            </div> : null}
                                        </React.Fragment>}
                                </React.Fragment>
                            );
                            break;
                        case "tree":
                            renRes = (
                                <React.Fragment>
                                    <DropdownTreeSelect
                                        className={field.name}
                                        data={field.noTop ? field.options.filter((op: any) => !op.top) : getTreeChildren(props.customer, field.options)}
                                        keepTreeOnSearch={true}
                                        texts={{ placeholder: field.placeholder || "Select" }}
                                        data-testid={field.name}
                                        onFocus={() => console.log("FOCUS")}
                                        onBlur={() => onBlur(wrap, field)}
                                        onChange={(currentNode, selectedNodes) => onChange(currentNode, selectedNodes, wrap, field)}
                                    />
                                </React.Fragment>
                            );
                            break;
                        case "toggleCheckbox":
                            renRes = (
                                <React.Fragment key={j}>
                                    <Accordion defaultActiveKey={field.collapse ? "-1" : "0"}>
                                        {renderAccordionWithCheckbox(wrap, field, j)}
                                    </Accordion>
                                </React.Fragment>
                            );
                            break;
                        case "checkbox":
                            renRes = (
                                <Row key={j}>
                                    {field.options.map((option: any, k: number) => {
                                        return (
                                            <Col sm={field.cols}>
                                                {option.value ? <Form.Check className="ms-3 mt-2" type={field.type} key={k} name={field.name} label={option.value} checked={field.value.indexOf(option.key) >= 0 || field.value.indexOf(option.value) >= 0} disabled={option.disabled} onChange={(ev) => inputChange(ev, wrap, field)} value={option.key} style={{ float: 'left' }} /> : null}
                                                {option.help ?
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        overlay={<Tooltip>{option.help}</Tooltip>}
                                                    >
                                                        <i className="fds-glyphs-info infoTxt" />
                                                    </OverlayTrigger> :
                                                    null
                                                }
                                            </Col>
                                        )
                                    })}
                                </Row>
                            );
                            break;
                        case "radio":
                            renRes = (
                                <div className="pb-2" key={j}>
                                    {field.options.map((option: any, k: number) => {
                                        return (
                                            <span className="d-inline-block pe-3" key={k}>
                                                {option.value ? <Form.Check className="mt-2 me-3" type={field.type} key={k} name={field.name} label={option.value} checked={field.value.indexOf(option.key) >= 0} disabled={option.disabled} onChange={(ev) => inputChange(ev, wrap, field)} value={option.key} /> : null}
                                                {option.help ?
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        overlay={<Tooltip>{option.help}</Tooltip>}
                                                    >
                                                        <i className="fds-glyphs-info infoTxt" />
                                                    </OverlayTrigger> :
                                                    null
                                                }
                                            </span>
                                        )
                                    })}
                                </div>
                            );
                            break;
                        case "textarea":
                            renRes = (
                                <Form.Control as="textarea" placeholder={wrap.placeholder} value={field.value} onChange={(ev) => inputChange(ev, wrap, field)} />
                            );
                            break;
                        case "blank":
                            renRes = null;
                            break
                        default:
                            renRes = (
                                <Form.Control type={field.type} key={j} id={field.name} name={field.name} maxLength={field.maxLength} disabled={field.disabled} placeholder={field.placeholder} onKeyDown={(ev) => { if (ev.key === "Enter") { ev.preventDefault(); return false; } }} onChange={(ev) => { inputChange(ev, wrap, field) }} value={field.value} />
                            );
                            break;
                    }

                    return (
                        <fieldset disabled={field.disabled} key={j}>
                            {field.show === undefined || field.show === true ? <div className={wrap.fields.length > 1 && wrap.fields.length !== j + 1 && wrap.type !== 'option' ? "me-3" : ""} style={{ flex: '1' }}>
                                {["toggleCheckbox", "checkbox", "radio"].indexOf(field.type) < 0 ?
                                    <>
                                        {!field.hideLabel ? <Form.Label htmlFor={field.name}>
                                            {field.label} {field.required ? <b title={requiredTxt}>*</b> : null}
                                            {field.help ? <OverlayTrigger
                                                placement="auto"
                                                overlay={<Tooltip>{field.help}</Tooltip>}
                                            >
                                                <i className="ms-2 fds-glyphs-info infoTxt" />
                                            </OverlayTrigger> : null}
                                        </Form.Label> : null}
                                    </> : null
                                }
                                {renRes}
                            </div> : null}
                        </fieldset>
                    );
                })}
            </div>

            {getTagWrap(wrap)}
        </div>)
    }

    useEffect(() => {
        //console.log("Template Updated...", dateRanges)
    }, [dateRanges])

    useEffect(() => {
        if (props.template?.templateName?.length) {
            setTemplateName(props.template.templateName)
        }
        return () => {
            setPrevPrior("")
            setTemplateName("")
        }
    }, [props.template.templateName])

    useEffect(() => {
        //console.log("props.template---", props.template);
        if (!props.template.wraps) {
            return;
        }

        const isMoreTotalAgencies = referenceDataRef[window.ENV.references.agency]?.length > 4000;
        const topOnly = referenceDataRef[window.ENV.references.agency]?.filter((ref: any) => ref.top);
        setAgencyTopOnly(isMoreTotalAgencies ? topOnly : referenceDataRef[window.ENV.references.agency]?.filter((ag: any) => ag["location type"] !== "Home" || ag.top));
        setAgencyHOLs(isMoreTotalAgencies ? topOnly : referenceDataRef[window.ENV.references.agency]?.filter((ag: any) => ag["location type"] === "Home" || ag.top));

        referenceDataRef[window.ENV.references.airport]?.forEach((item: any) => {
            aMap[item.code] = item;
        });

        props.template.wraps.forEach((wrap: any, i: number) => {
            wrap.fields.forEach((field: any, j: number) => {
                if (field.url && referenceDataRef[field.url]) {
                    field.options = field.type === "tree" ? toTree(field.codeKey, field.labelKey, referenceLoadedRef.status ? referenceDataRef[field.url] : field.localStorage) :
                        (wrap?.checks && props.customer.data_content.indexOf(wrap?.checks) >= 0) && props.customer?.code.length > 0 ? [...referenceDataRef[field.url].filter((opt: any) => opt[field.codeKey] === props.customer.code), { acct: "all", code: "all_airlines", description: "All Other Airlines", name: "All Other Airlines" }] : referenceDataRef[field.url];
                    return field;
                } else if (field.store && state[field.store]) {
                    field.options = state[field.store]
                        .filter((item: any) =>
                            item.type === "apap" &&
                            (
                                item.shared === "1" ||
                                item.user_id?.toLowerCase() === (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase() ||
                                item.org === (getOts()?.idToken?.orgName || getOts()?.accessToken?.claims?.org)
                            )
                        )
                        .map((item: any) => {
                            item[field.codeKey] = item.values.toString()
                            item['top'] = true;
                            return item;
                        });
                    return field;
                }
            })
        });

        setTemplate({ ...checkDeps(props.template) });
    }, [referenceDataRef, referenceLoadedRef, props.template, props.customer, state, checkDeps]);

    return (
        <Form onSubmit={(e) => { e.preventDefault(); return false }}>
            {template.wraps.map((wrap: any, i: number) => {
                return (<React.Fragment key={i}>
                    {
                        wrap.show === false
                            || (wrap.hideFor && wrap.hideFor?.entity.indexOf(props.customer.entity) >= 0)
                            || (wrap.showFor?.subscription && props.customer.data_content.indexOf(wrap.showFor.subscription) < 0)
                            || (wrap.showFor?.dataSource && wrap.showFor?.dataSource.map((src: String) => props.customer.data_source.includes(src)).indexOf(true) < 0)
                            ? null : <div className={`template-block mt-2 wrap${i}`}>
                                {wrap.showName ? <h3 className="mb-0 pt-3 formName">
                                    {wrap.name} {wrap.required ? <b title={requiredTxt}>*</b> : wrap.requiredGroup ? <sup title={requiredGroupTxt}>{wrap.requiredGroup}*</sup> : null}
                                    {wrap.help ?
                                        <OverlayTrigger
                                            placement="auto"
                                            overlay={<Tooltip>{wrap.help}</Tooltip>}
                                        >
                                            <i className="ms-2 fds-glyphs-info infoTxt" />
                                        </OverlayTrigger>
                                        : null}
                                </h3> : null}
                                {(wrap.excludable || wrap.masking) && (!wrap.checks || (wrap.checks && props.customer.data_content.indexOf(wrap.checks) < 0)) ?
                                    <div className={`exclude ${wrap.type || ''} ${wrap.showName ? "titled" : "notitled"}`}>
                                        <Form.Check
                                            className={`custom-switch-on-off${wrap.masking ? '-unmask' : ""} custom-control custom-switch danger`}
                                            type="switch"
                                            name={wrap.name + (wrap.masking ? MASK : EXCLUDE)}
                                            id={wrap.name + (wrap.masking ? MASK : EXCLUDE)}
                                            data-testid={wrap.name + (wrap.masking ? MASK : EXCLUDE)}
                                            label=""
                                            onChange={(ev) => {
                                                wrap.fields.forEach((field: any) => {
                                                    inputChange(ev, wrap, field)
                                                })
                                            }}
                                            value={wrap.masking ? wrap.unmask : wrap.exclude}
                                            checked={wrap.masking ? wrap.unmask : wrap.exclude}
                                        />
                                    </div> : null}
                                {wrap.isTitle ?
                                    <>
                                        <Row className="mb-0 pt-3">
                                            <Col>
                                                <h3 className="mb-0 formTitle">
                                                    {wrap.name} {wrap.required ? <b title={requiredTxt}>*</b> : wrap.requiredGroup ? <sup title={requiredGroupTxt}>{wrap.requiredGroup}*</sup> : null}
                                                    {wrap.help ? <OverlayTrigger
                                                        placement="auto"
                                                        overlay={<Tooltip>{wrap.help}</Tooltip>}
                                                    >
                                                        <i className="ms-2 fds-glyphs-info infoTxt" />
                                                    </OverlayTrigger> : null}
                                                </h3>
                                            </Col>
                                            {wrap.lookup ?
                                                <Col style={{ textAlign: 'right' }} md={5}>
                                                    <i className="fds-glyphs-search me-1" style={{ position: 'relative', top: '3px' }} />
                                                    <a href={`/#/ref/${wrap.lookup}`} style={{ textTransform: "capitalize" }}>{wrap.lookup} Lookup</a>
                                                </Col> :
                                                null
                                            }
                                        </Row>
                                    </> : null}
                                {wrap.fields.length > 0 ? <>
                                    {wrap.fields.length > 1 && wrap.type === 'option' ?
                                        <Tabs className="nav-scoped mt-2" defaultActiveKey={0} activeKey={wrap.fields.findIndex((f: any) => f.show)} transition={false}
                                            onSelect={(key: any) => {
                                                const prev: any = { ...template };

                                                for (let k = 0; k < prev.wraps[i].fields.length; k++) {
                                                    prev.wraps[i]['fields'][k].show = false;
                                                }
                                                prev.wraps[i]['fields'][key].show = true;

                                                setTemplate(prev);
                                                props.onChange(prev);
                                            }}>
                                            {
                                                wrap.fields.map((field: any, j: number) => {
                                                    let filledIndex = -1;
                                                    for (let fi = 0; fi < wrap.fields?.length; fi++) {
                                                        if (wrap.fields[fi].value?.length > 0) {
                                                            filledIndex = fi;
                                                        }
                                                    }
                                                    return (
                                                        field.disableFor && field.disableFor.entity.indexOf(props.customer.entity) >= 0 ? null :
                                                            <Tab className={`nav-item nav-link`}
                                                                eventKey={j}
                                                                title={field.tab ? field.tab : field.label}
                                                                key={j}
                                                                disabled={field?.disabled || (wrap?.disableEmpty && filledIndex >= 0 && filledIndex !== j)}
                                                            >
                                                                {getTab(wrap)}
                                                            </Tab>
                                                    );
                                                })
                                            }
                                        </Tabs>
                                        : <div>{getTab(wrap)}</div>}
                                </> : null}
                            </div>
                    }
                </React.Fragment>);
            })}
        </Form >
    )
};